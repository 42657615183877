import React from 'react';
import { Check } from 'react-feather';
import { useHistory } from 'react-router-dom';

import styles from './Styles.module.css';
import { Text, Button } from '../../ui';

export default function Component() {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <div className={styles.thanksBox}>
        <Check size={40} />
        <Text text="¡Felicidades!" variant="h2" />
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pellentesque felis odio." variant="body" color="black" />
        <Button text="Continuar" onClick={() => history.push('/catalog')} />
      </div>
    </div>
  );
}
