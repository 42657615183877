import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Styles.module.css';
import { Text, Input } from '..';

const Component = (props) => {
  const {
    classes,
    title,
    variant,
    state,
    value,
    onChange,
    comboOptions,
    errorMessage,
    placeholder,
    onPressEnter,
    link,
    children,
    setCardType
  } = props;

  const [active, setActive] = useState(false);
  const [openCombo, setOpenCombo] = useState(false);

  const className = classNames(
    styles.root,
    classes.root
  );

  const classNameBox = classNames(
    styles.box,
    styles[`variant-${variant}`],
    styles[`state-${state}`],
    classes.box,
    classes[`variant-${variant}`],
    classes[`state-${state}`],
    {
      [styles['state-error']]: errorMessage !== null,
      [classes['state-error']]: errorMessage !== null,
      [styles['state-active']]: active,
      [classes['state-active']]: active
    }
  );

  const classNameError = classNames(
    styles.error,
    classes.error,
    {
      [styles.withoutError]: errorMessage === null,
      [classes.withoutError]: errorMessage === null
    }
  );

  const notFullSizeVariants = ['combo', 'card-number'];

  const classNameValue = classNames(
    styles.value,
    classes.value,
    {
      [styles.fullSizeValue]: (!notFullSizeVariants.includes(variant) && link === null),
      [classes.fullSizeValue]: (!notFullSizeVariants.includes(variant) && link === null)
    }
  );

  const getTextColor = () => {
    if (state === 'dark') return 'light';
    return 'dark';
  };

  const getDisabled = () => {
    switch (variant) {
      case 'read-only':
      case 'combo':
      case 'button':
        return true;

      default:
        return false;
    }
  };

  const handleOnChange = (val) => {
    const newValue = val;
    onChange(newValue);
  };

  const handleOnClickComboItem = (newValue) => {
    setOpenCombo(false);
    onChange(newValue);
  };

  const handleOnFocus = () => {
    setActive(true);
    setOpenCombo(variant === 'combo');
  };

  return (
    <div className={className}>
      <div className={classNameBox}>
        <Text
          classes={{ root: styles.title }}
          text={title}
          variant="input"
          color={(variant === 'read-only' ? 'gray' : 'black')}
        />
        <Input
          classes={{ root: classNameValue }}
          color={getTextColor()}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          type={variant}
          disabled={getDisabled()}
          onPressEnter={onPressEnter}
          setCardType={setCardType}
        />
        {
          link && (
          <Link to={link.href}>
            <Text classes={{ root: styles.link }} color="black" variant="input" text={link.label} />
          </Link>
          )
        }
        {
          children
        }
        {
          variant === 'combo' && (
          <>
            <ChevronDown className={styles.comboIcon} onClick={() => setOpenCombo(true)} />
            {
              (openCombo && comboOptions.length > 0) && (
                <div
                  className={styles.combo}
                  onMouseLeave={() => setOpenCombo(false)}
                >
                  {
                    comboOptions.map(({ id, value: newValue }) => (
                      <Text key={id} classes={{ root: styles['combo-item'] }} text={newValue} onClick={() => handleOnClickComboItem(newValue)} />
                    ))
                  }
                </div>
              )
            }
          </>
          )
        }
      </div>
      {errorMessage && <Text classes={{ root: classNameError }} variant="alert" color="error" text={errorMessage} />}
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  state: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  comboOptions: PropTypes.arrayOf(PropTypes.object),
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  link: PropTypes.objectOf(PropTypes.string),
  setCardType: PropTypes.func
};

const defaultProps = {
  classes: {},
  children: null,
  title: 'title',
  variant: 'email',
  required: false,
  state: 'normal',
  comboOptions: [],
  errorMessage: null,
  id: null,
  value: null,
  onChange: null,
  onPressEnter: null,
  placeholder: '',
  link: null,
  setCardType: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
