import React, { useState, useEffect, useCallback } from 'react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import PropTypes from 'prop-types';

import Swiper from './Swiper';
import Slide from './Slide';
import 'swiper/css/swiper.css';
import styles from './Styles.module.css';
import constants from '../../../constants/constants';

const Component = (props) => {
  const {
    images,
    showButtons,
    showCurrentIndex
  } = props;

  const imagesValidate = images.length > 0 ? images : [
    {
      id: -1,
      path_file: constants.notImageAvailableDetail
    }
  ];

  const [swiper, updateSwiper] = useState(null);
  const [currentIndex, updateCurrentIndex] = useState(0);

  const params = {
    rebuildOnUpdate: true,
    getSwiper: updateSwiper,
    centeredSlides: false,
    loop: imagesValidate.length > 1,
    autoHeight: false,
    height: 120,
    slidesPerView: 2,
    spaceBetween: 0,
    containerClass: `${styles.containerClass}`,
    wrapperClass: `${styles.wrapperClass}`,
    slideClass: `${styles.slideClass}`,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true
    }
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  /*
  const renderSlider = useCallback(
    ({ id, path_file }) => {
      const image = id !== -1 ? `${constants.cdn}${path_file}` : path_file;
      return (
        <div key={`slide_${id}`}>
          <Slide image={image} />
        </div>
      );
    },
    []
  );
  */

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
    swiper
  ]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex);
    }
    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [swiper, updateIndex]);

  return (
    <SimpleReactLightbox>
      <div className={styles.root}>
        <Swiper params={params}>
          {imagesValidate.map((image, index) => (
            <div key={`slide_${image.id}`}>
              <Slide index={index} image={image.id !== -1 ? `${constants.cdn}${image.path_file}` : image.path_file} />
            </div>
          ))}
        </Swiper>
        <div className={styles.hiddenWrapper}>
          <SRLWrapper>
            {imagesValidate.map((image) => (
              <img src={`${constants.cdn}${image.path_file}`} alt="" />
            ))}
          </SRLWrapper>
        </div>

        <div>
          {
          showButtons && (
            <div className={styles.buttons}>
              <button
                type="button"
                onClick={goPrev}
              >
                Anterior
              </button>
              {
                showCurrentIndex && (
                  <span>{currentIndex}</span>
                )
              }
              <button
                type="button"
                onClick={goNext}
              >
                Siguiente
              </button>
            </div>
          )
        }
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

const propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  showButtons: PropTypes.bool,
  showCurrentIndex: PropTypes.bool
};

const defaultProps = {
  images: [],
  showButtons: false,
  showCurrentIndex: false
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
