import React from 'react';
import PropTypes from 'prop-types';

import styles from './Styles.module.css';

const Component = (props) => {
  const { children } = props;
  return (
    <div className={styles.root}>
      { children }
    </div>
  );
};

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
