import { Menu, X, Circle } from 'react-feather';
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Styles.module.css';
import { Text, Divider, Button } from '..';

const Component = (props) => {
  const {
    classes,
    items,
    textColor,
    // textVariant,
    withMobile
  } = props;

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const className = classNames(
    styles.root,
    classes.root
  );
  const textClasses = classNames(
    styles['text-menu-item'],
    classes['text-menu-item'],
    {
      [styles['with-mobile-text-menu-item']]: withMobile,
      [classes['with-mobile-text-menu-item']]: withMobile
    }
  );
  const menuMobileClasses = classNames(
    styles['menu-mobile'],
    classes['menu-mobile'],
    {
      [styles['with-mobile-menu-mobile']]: withMobile,
      [classes['with-mobile-menu-mobile']]: withMobile
    }
  );
  const menuListMobileClasses = classNames(
    styles['menu-list-mobile'],
    classes['menu-list-mobile'],
    {
      [styles['show-menu-list-mobile']]: displayMobileMenu,
      [classes['show-menu-list-mobile']]: displayMobileMenu
    }
  );

  const handleOnClick = (onClick) => {
    setDisplayMobileMenu(false);
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className={className}>
      {
        items
        && items.map(({ id, text, onClick }) => (
          <Button
            key={id}
            classes={{ root: textClasses }}
            text={text}
            color={textColor}
            variant="simple"
            onClick={() => handleOnClick(onClick)}
          />
        ))
      }
      <div className={menuMobileClasses}>
        <div
          onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
        >
          <Menu />
        </div>
        {withMobile
          && (
          <div
            className={menuListMobileClasses}
          >
            <div className={styles['menu-content']}>
              <div className={styles['menu-header']}>
                <Circle />
                <div className={styles['menu-header-icon']} onClick={() => setDisplayMobileMenu(false)}>
                  <X />
                </div>
              </div>
              <Divider type="withoutMargins" />
              <ul>
                {
                  items
                  && items.map(({ id, text, onClick }) => (
                    <li key={id}>
                      <Text classes={{ root: styles['text-mobile-item'] }} key={id} text={text} variant="h3" onClick={() => handleOnClick(onClick)} />
                      <Divider type="withoutMargins" />
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          )}
      </div>
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  textColor: PropTypes.string,
  textVariant: PropTypes.string,
  withMobile: PropTypes.bool
};

const defaultProps = {
  classes: {},
  items: [],
  textColor: 'normal',
  textVariant: 's',
  withMobile: true
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
