import {
  call, put, takeEvery, delay
} from 'redux-saga/effects';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  FETCH_MESSAGE,
  ADD_MESSAGE,
  DELETE_MESSAGE,
  ADD_ERROR_MESSAGE,
  DELETE_ERROR_MESSAGE,
  FETCH_REGISTER,
  REGISTER,
  FETCH_LOGIN,
  LOGIN,
  FETCH_GET_PROFILE,
  PROFILE,
  FETCH_LOGOUT,
  LOGOUT,
  FETCH_PASSWORD_RECOVER,
  PASSWORD_RECOVER,
  FETCH_PASSWORD_TOKEN,
  PASSWORD_TOKEN,
  FETCH_GET_USERS,
  USERS,
  FETCH_CHANGE_USER_STATUS,
  FETCH_GET_ITEMS,
  ITEMS,
  ADMIN_ITEMS,
  FETCH_GET_ADMIN_ITEMS,
  FETCH_GET_ADMIN_ITEM,
  FETCH_GET_ITEM,
  FETCH_POST_ITEM,
  FETCH_UPDATE_ITEM,
  FETCH_DELETE_ITEM,
  FETCH_ADD_IMAGE_ITEM,
  FETCH_DELETE_IMAGE_ITEM,
  FETCH_UPDATE_IMAGE_INDEX,
  ITEM,
  FETCH_UPDATE_USER,
  FETCH_UPDATE_PROFILE,
  PASSWORD_RESET,
  FETCH_PASSWORD_RESET,
  ADMIN_ITEM,
  FETCH_CHECKOUT,
  CHECKOUT,
  FETCH_PROFILE_SEPARATIONS,
  PROFILE_SEPARATIONS
} from '../actions';
import constants from '../../constants/constants';
import statesIds from '../../constants/stateIds';
import certificateIds from '../../constants/certificateIds';
import typeIds from '../../constants/typeIds';
import { getPathId } from '../../utils/helpers';

const comboIds = {
  certificated: certificateIds,
  type_id: typeIds,
  state_id: statesIds
};

const httpClient = axios.create();
httpClient.defaults.timeout = 6000;

const cookies = new Cookies();

const getHeaders = (newHeaders = {}) => {
  const token = cookies.get('token');
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    ...newHeaders
  };
  return headers;
};

const fetch = (config) => {
  const {
    method, url, data, headers
  } = config;
  const path = `${constants.api}/${url}`;
  const newConfig = {
    method,
    url: path,
    data,
    headers: getHeaders(headers)
  };
  return httpClient(newConfig)
    .then((res) => res.data)
    .catch((error) => {
      let newData = { success: false };
      if (error.response && error.response.data) {
        newData = {
          ...newData,
          ...error.response.data
        };
      } else if (error.request) {
        newData = {
          ...newData,
          ...error.request
        };
      } else {
        newData = { ...newData, message: 'Sin respuesta.' };
      }
      return newData;
    });
};

export function* fetchMessage({ payload }) {
  const { id, message } = payload;
  yield put({ type: DELETE_MESSAGE, payload: [id] });
  yield put({ type: DELETE_ERROR_MESSAGE, payload: [id] });
  const success = payload.success ?? payload.success === true;
  const typeNewMessage = success ? ADD_MESSAGE : ADD_ERROR_MESSAGE;
  const typeDeleteMessage = success ? DELETE_MESSAGE : DELETE_ERROR_MESSAGE;
  yield put({
    type: typeNewMessage,
    payload: {
      [id]: message
    }
  });
  yield delay(constants.deleteMessageDelay);
  yield put({ type: typeDeleteMessage, payload: [id] });
}

export function* fetchRegister({ payload }) {
  const url = 'register';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: REGISTER, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'register'
    }
  });
}

export function* fetchProfile({ history }) {
  const url = 'profile';
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: PROFILE, payload: response });
  if (!response.success) {
    history.push('/');
  }
}

export function* fetchLogin({ payload, history }) {
  const url = 'login';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: LOGIN, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'login'
    }
  });
  if (response.success) {
    cookies.remove('token', { path: '/' });
    cookies.set('token', response.data.token, { path: '/' });
    switch (response.data.user.role_id) {
      case 1:
        history.push('/backoffice');
        break;
      default:
        history.push('/catalog');
        break;
    }
  }
}

export function* fetchLogout({ history }) {
  cookies.remove('token');
  const response = {
    success: true,
    message: 'Sesion closed successfully.'
  };
  yield put({ type: LOGOUT });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'logout'
    }
  });
  if (response.success) {
    history.push('/');
  }
}

export function* fetchPasswordRecover({ payload }) {
  const url = 'password/create';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: PASSWORD_RECOVER, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'password'
    }
  });
}

export function* fetchPasswordToken({ payload, history }) {
  const url = `password/find/${payload.token}`;
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  if (!response.success) {
    history.push('/password/recover');
  }
  yield put({ type: PASSWORD_TOKEN, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'password'
    }
  });
}

export function* fetchPasswordReset({ payload, history }) {
  const url = 'change_password/';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  if (response.success) {
    history.push('/login');
  }
  yield put({ type: PASSWORD_RESET, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'password'
    }
  });
}

export function* fetchUsers() {
  const url = 'user';
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: USERS, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'user'
    }
  });
}

export function* fetchChangeUserStatus({ payload }) {
  const url = 'change_status';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: FETCH_GET_USERS, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'change status'
    }
  });
}

export function* fetchUpdateUser({ payload, history }) {
  const url = `user/${payload.id}`;
  const data = {
    ...payload
  };
  const config = {
    method: 'put',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  if (response.success) {
    history.push('/backoffice');
  }
  yield put({ type: FETCH_GET_USERS, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'update user'
    }
  });
}

export function* fetchUpdateProfile({ payload, history }) {
  const url = `user/${payload.id}`;
  const data = {
    ...payload
  };

  data.state_id = comboIds.state_id.find((x) => x.value === data.state_id).id;
  const config = {
    method: 'put',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: FETCH_GET_PROFILE, history });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'update profile'
    }
  });
}

export function* fetchItems({ payload }) {
  const url = `item?page=${payload}`;
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: ITEMS, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'items'
    }
  });
}

export function* fetchItem({ payload }) {
  const url = `item/${payload}`;
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: ITEM, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchAdminItem({ payload }) {
  const url = `item/${payload}`;
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: ADMIN_ITEM, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchAdminItems() {
  const url = 'items_master';
  const config = {
    method: 'get',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: ADMIN_ITEMS, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'items'
    }
  });
}

export function* fetchPostItem({ payload, history }) {
  const url = 'item';
  const data = {
    ...payload
  };
  Object.keys(comboIds).forEach((id) => {
    data[id] = comboIds[id].find((x) => x.value === data[id]).id;
  });
  const bodyFormData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Array) {
      switch (key) {
        case 'image':
          data[key].forEach((subData, index) => {
            bodyFormData.append(`${key}[${index}]`, subData);
          });
          break;
        default:
          data[key].forEach((subData, index) => {
            bodyFormData.append(`${key}[${index}]`, JSON.stringify(subData));
          });
          break;
      }
    } else if (key === 'certificated') bodyFormData.append(key, !!data[key]);
    else bodyFormData.append(key, data[key]);
  });
  const config = {
    method: 'post',
    url,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  const response = yield call(() => fetch(config));
  if (response.success) {
    history.push('/backoffice/catalogs');
  }
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchUpdateItem({ payload, history }) {
  const itemId = getPathId(history);
  const url = `item/${itemId}`;
  const { image, ...others } = payload;
  const data = {
    ...others
  };
  Object.keys(comboIds).forEach((id) => {
    data[id] = comboIds[id].find((x) => x.value === data[id]).id;
  });
  const config = {
    method: 'put',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  if (response.success) {
    if (image.length > 0) {
      yield put({
        type: FETCH_ADD_IMAGE_ITEM,
        payload: {
          item_id: itemId,
          image
        }
      });
    }
    history.push('/backoffice/catalogs');
  }
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchDeleteItem({ payload }) {
  const url = `item/${payload}`;
  const config = {
    method: 'delete',
    url
  };
  const response = yield call(() => fetch(config));
  yield put({ type: FETCH_GET_ADMIN_ITEMS });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchAddImageItem({ payload }) {
  const url = 'add_images_item';
  const data = {
    ...payload
  };
  const bodyFormData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Array) {
      data[key].forEach((subData, index) => {
        bodyFormData.append(`${key}[${index}]`, subData);
      });
    } else bodyFormData.append(key, data[key]);
  });
  const config = {
    method: 'post',
    url,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  const response = yield call(() => fetch(config));
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchDeleteImageItem({ payload }) {
  const url = 'delete_image_item';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  // yield put({ type: FETCH_GET_ADMIN_ITEM, payload: payload.item_id });
  // yield put({ type: FETCH_GET_ADMIN_ITEMS });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchUpdateImageIndex({ payload }) {
  const url = 'images_index';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  // yield put({ type: FETCH_GET_ADMIN_ITEM, payload: payload.item_id });
  // yield put({ type: FETCH_GET_ADMIN_ITEMS });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'item'
    }
  });
}

export function* fetchCheckout({ payload }) {
  const url = 'separation_payment';
  const data = {
    ...payload
  };
  const config = {
    method: 'post',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: CHECKOUT, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'checkout'
    }
  });
}

export function* fetchProfileSeparations({ payload }) {
  const url = 'separation';
  const data = {
    ...payload
  };
  const config = {
    method: 'get',
    url,
    data
  };
  const response = yield call(() => fetch(config));
  yield put({ type: PROFILE_SEPARATIONS, payload: response });
  yield put({
    type: FETCH_MESSAGE,
    payload: {
      ...response,
      id: 'checkout'
    }
  });
}

function* watchActions() {
  yield takeEvery(FETCH_MESSAGE, fetchMessage);
  yield takeEvery(FETCH_REGISTER, fetchRegister);
  yield takeEvery(FETCH_GET_PROFILE, fetchProfile);
  yield takeEvery(FETCH_LOGIN, fetchLogin);
  yield takeEvery(FETCH_LOGOUT, fetchLogout);
  yield takeEvery(FETCH_PASSWORD_RECOVER, fetchPasswordRecover);
  yield takeEvery(FETCH_PASSWORD_TOKEN, fetchPasswordToken);
  yield takeEvery(FETCH_PASSWORD_RESET, fetchPasswordReset);
  yield takeEvery(FETCH_GET_USERS, fetchUsers);
  yield takeEvery(FETCH_UPDATE_USER, fetchUpdateUser);
  yield takeEvery(FETCH_UPDATE_PROFILE, fetchUpdateProfile);
  yield takeEvery(FETCH_CHANGE_USER_STATUS, fetchChangeUserStatus);
  yield takeEvery(FETCH_GET_ITEMS, fetchItems);
  yield takeEvery(FETCH_GET_ITEM, fetchItem);
  yield takeEvery(FETCH_GET_ADMIN_ITEM, fetchAdminItem);
  yield takeEvery(FETCH_GET_ADMIN_ITEMS, fetchAdminItems);
  yield takeEvery(FETCH_POST_ITEM, fetchPostItem);
  yield takeEvery(FETCH_UPDATE_ITEM, fetchUpdateItem);
  yield takeEvery(FETCH_DELETE_ITEM, fetchDeleteItem);
  yield takeEvery(FETCH_ADD_IMAGE_ITEM, fetchAddImageItem);
  yield takeEvery(FETCH_DELETE_IMAGE_ITEM, fetchDeleteImageItem);
  yield takeEvery(FETCH_UPDATE_IMAGE_INDEX, fetchUpdateImageIndex);
  yield takeEvery(FETCH_CHECKOUT, fetchCheckout);
  yield takeEvery(FETCH_PROFILE_SEPARATIONS, fetchProfileSeparations);
}

export default watchActions;
