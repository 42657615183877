import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Styles.module.css';
import { Header } from '../../../layouts/FormsLayout';
import {
  Button, Text
} from '../../../ui';

const Component = (props) => {
  const { title, body } = props;

  const history = useHistory();

  const classNameColumnLeft = classNames(
    styles.column,
    styles['column-left']
  );

  const classNameColumnRight = classNames(
    styles.column,
    styles['column-right']
  );

  return (
    <div className={styles.root}>
      <Header />
      <div className={classNameColumnLeft} />
      <div className={classNameColumnRight}>
        <Button
          classes={{ root: styles.button }}
          text="Regresar"
          variant="simple"
          onClick={() => history.goBack()}
        />
        <Text
          classes={{ root: styles.title }}
          text={title}
          variant="h3"
        />
        {body.map((text) => (
          <Text
            classes={{ root: styles.body }}
            text={text}
          />
        ))}
      </div>
    </div>
  );
};

const propTypes = {
  title: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string)
};

const defaultProps = {
  title: 'Términos y condiciones',
  body: ['I.- Capacidad',

    '1.1.- Las Partes acuerdan en que todos los Servicios ofrecidos por el Sitio o la Empresa, podrán ser utilizados únicamente por personas y Usuarios que tengan capacidad legal para contratar. No podrán utilizar los servicios los Usuarios o personas que no tengan esa capacidad, los menores de edad y Usuarios que hayan sido suspendidos temporalmente o inhabilitados definitivamente por personal de la Empresa, así como cualquier persona que tenga incapacidad natural o legal conforme a lo dispuesto en el artículo 450 (Cuatrocientos Cincuenta) del Código Civil Federal para los Estados Unidos Mexicanos.',
    'II.- Servicios del sitio y/o aplicaciones',

    '2.1.- La Empresa autorizará a su entera discreción a cualquier Usuario que se registre en el Sitio y que quiera utilizar los Servicios ofrecidos en el mismo, reservándose el derecho de permitir o prohibir su uso y acceso ya sea de manera temporal o permanente a cualquier persona y/o Usuario y en cualquier momento.',

    '2.2.- El Usuario reconoce que el Sitio, las Aplicaciones y en consecuencia la Empresa ofrecen una serie de plataformas tecnológicas y digitales que permiten a los Usuarios interactuar entre sí y facilitan el común acuerdo entre los mismos para la realización de diversos servicios requeridos y/u ofrecidos por los Usuarios. Por lo que el Usuario asume cualquier responsabilidad laboral, si la hubiere, por cualquier persona o Usuario o tercero que contacte, contrate, emplee o reciba cualquier Servicio ofrecido dentro del Sitio o de cualquier Aplicación, obligándose en este acto a cumplir con todas las obligaciones como patrón con relación a dichas personas, Usuarios, personal o cualquier tercero, de conformidad con las leyes aplicables en la República Mexicana. Cualquier Usuario que haga uso del Sitio y/o de las Aplicaciones y preste o reciba cualquier Servicio será completamente responsable en caso de ser necesario por el pago y contribuciones que para tal efecto pudieran ser requeridas por las leyes de los Estados Unidos Mexicanos, en materia laboral, fiscal y de seguridad social de conformidad con las leyes aplicables en la República Mexicana, obligándose a indemnizar y a sacar en paz y a salvo a la Empresa, sus empleados, agentes, afiliados y accionistas de toda responsabilidad laboral o de cualquier otra índole, relacionada con el uso del Sitio, Aplicaciones y del Servicio o Servicios que se presenten o reciban derivados de éstos últimos.',

    '2.3.- En tal virtud, el Usuario libera en este acto a se obliga a defender, indemnizar y a sacar en paz y a salvo a la Empresa, sus empleados, agentes, afiliados, accionistas y terceras personas de cualesquier reclamación, acción, demanda, obligación, costos, daños, pérdidas, deficiencias, gastos, incluyendo honorarios razonables de abogados, que se deriven de hechos, actos u omisiones relacionados directa o indirectamente con el uso del Sitio, las Aplicaciones, así como del Contenido publicado por el Usuario en dicho Sitio o sus respectivas Aplicaciones.',

    'III.- Alcance de los servicios',

    '3.1.- El presente acuerdo de voluntades entre las Partes no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre la Empresa, sus empleados, agentes, afiliados, accionistas con el Usuario. El Usuario reconoce y acepta que la Empresa y el Sitio no son ni serán parte en ninguna operación, ni tienen control alguno sobre la calidad, seguridad o legalidad de los Servicios anunciados, la veracidad o exactitud de los anuncios de Servicios o de la capacidad de los Usuarios para prestar los Servicios en cuestión. La Empresa no puede asegurar que un Usuario completará una operación o Servicio pactado entre dos o más Usuarios, ni podrá verificar la identidad o Datos Personales ingresados por los Usuarios. El Sitio y la Empresa no garantizan la completa veracidad de la publicidad hecha por terceros que aparezca en el Sitio y/o en las Aplicaciones y no será responsable por la correspondencia o contratos aún siendo privados que el Usuario celebre con otros Usuarios o con terceros.',

    'IV.- Obligaciones',

    '4.1.- El o los Usuarios interesados en utilizar el Sitio para ofrecer un servicio, debidamente registrados en el mismo, (en lo sucesivo los "Usuarios Prestadores de Servicios") deberán proporcionar toda la información requerida y solicitada por el Sitio para que les facilite el ofrecimiento de sus servicios.',

    '4.2.- Los Usuarios interesados en utilizar el Sitio para recibir un servicio, debidamente registrados en el mismo, (en lo sucesivo los "Usuarios en Búsqueda de un Servicio") deberán proporcionar toda la información requerida y solicitada por el Sitio para facilitar la búsqueda de Usuarios Prestadores de Servicios y con ello encontrar el servicio deseado. ,',

    '4.3.- Una vez aceptada la realización de un servicio debidamente ofrecido por un Usuario Prestador de Servicios en el Sitio o en alguna Aplicación por otro Usuario, el primero estará obligado a comunicarse con el Usuario en Búsqueda de un Servicio y completar la operación en los términos y condiciones aceptados y pactados dentro del Sitio, salvo que la operación esté prohibida por las leyes de los Estados Unidos Mexicanos o los Términos y Condiciones Generales y demás políticas del Sitio, en cuyo caso no estará obligado a concretar la operación',

    'V.- Publicación de imágenes, información y contenido digital',

    '5.1.- La Empresa autoriza al Usuario a cargar, subir y publicar imágenes, fotografías, información o cualquier contenido digital permitido dentro del Sitio o de las Aplicaciones , ya sea para la creación de perfil, publicación de una oferta de servicio o para describir y/o explicar la necesidad de un Servicio. ,',

    '5.2.- La Empresa y su personal podrán impedir en todo momento y a su entera discreción la publicación de cualquier imagen, fotografía, información o cualquier contenido digital, que a criterio de la Empresa y/o de su personal consideren que no cumple con los presentes Términos y Condiciones, la buena moral y/o se encuentra prohibido por la Ley.',

    'VI.- Servicios prohibidos',

    '6.1.- El y/o los Usuarios en este acto aceptan y reconocen que solo podrán ser solicitados y/u ofrecidos aquellos servicios que no se encuentren tácita o expresamente prohibidos en los presentes Términos y Condiciones y demás políticas de la Empresa o por la Ley correspondiente de los Estados Unidos Mexicanos.',

    '6.2.- El Usuario manifiesta que los Servicios y en general el Contenido del Usuario es lícito y permitido por la Ley.',

    'VII.- Fallas en el sistema',

    '7.1.- El Sitio y la Empresa no se responsabilizan por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. El Sitio y la Empresa tampoco serán responsables por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios en este acto renuncian y por consecuencia no podrán imputarle responsabilidad alguna ni exigir al Sitio a la Empresa, sus empleados o accionistas, pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. El Sitio y la Empresa no garantizan el acceso y uso continuado o ininterrumpido de su Sitio o de las Aplicaciones. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena al Sitio o a las Aplicaciones; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. El Sitio, la Empresa y sus empleados y accionistas no serán responsables por ningún error u omisión contenidos en su Sitio o Aplicaciones.',

    'VIII.- Propiedad intelectual, derechos de autor y privacidad de la información',

    '8.1.- El dominio www.triangulo.app, sus derivados, así como el contenido del Sitio, ya sea publicado o cargado por la Empresa o por los Usuarios, tales como, imágenes, fotografías, publicidad, nombres comerciales, cualquier tipo de vídeo, información, logotipos, iconos, software, audio y cualquier otro contenido digital incluyendo el diseño del Sitio y/o sus Aplicaciones (en lo sucesivo, el “Contenido") son propiedad exclusiva de la Empresa sus subsidiarias y de sus accionistas y están protegidos bajo las leyes de propiedad industrial, intelectual, derechos de autor o su ley correspondiente. En Consecuencia, el Usuario acepta en este acto y renuncia asimismo a reclamar en cualquier tiempo y forma cualquier adjudicación o propiedad del Contenido como derechos autor, marcas, patentes u otros derechos de propiedad intelectual o industrial en el Sitio o sus Aplicaciones. ,',

    '8.2.- El Usuario reconoce y acepta que al hacer uso del Sitio o de cualquiera de sus Aplicaciones otorga a la Empresa a través del Sitio y/o de sus Aplicaciones, en ese mismo acto, el permiso y licencia universales para utilizar, publicar, distribuir, publicitar y en general hacer uso de cualquier modo lícito y permitido por la Ley de el Contenido, información y datos personales y en general de cualquier contenido publicado en el Sitio o sus respectivas Aplicaciones, renunciando en consecuencia a cualesquier derecho y/o regalía o prestación conforme a la Ley Mexicana o extranjera que pudiese llegar a corresponderles por el Contenido utilizado y/o proporcionado por el mismo Usuario. El Usuario podrá manifestar su inconformidad y solicitar a la Empresa la privacidad de su información personal a través de notificación escrita a la siguiente dirección de correo: contacto@triangulo.app ,',

    '8.3.- El Usuario se compromete a no divulgar, reproducir, transmitir, retransmitir, publicar, o fijar por cualquier otro medio que no sea el Sitio o sus respectivas Aplicaciones el Contenido información publicada dentro de los mismos, ya sea impreso, fonográfico, gráfico, audiovisual, electrónico u otro similar, en México o en el extranjero, en su beneficio, o utilizar o usar cualquier tipo de material o información que se le hubiese proporcionado o desarrollado al amparo del presente Contrato para su propio beneficio o de terceras personas. En vista de lo anterior el Usuario acepta que tendrá responsabilidad ilimitada, respecto de la reparación de los daños y perjuicios que ocasione a la Empresa por el uso o revelación del Contenido materia del presente Contrato por cualquier otro medio que no sea el Sitio o sus respectivas Aplicaciones.',

    'IX.- Jurisdicción y legislación aplicable',

    '9.1.- El registro como Usuario en el Sitio es gratuito. Solamente los Usuarios Prestadores de Servicios deberán pagar a la Empresa un costo por el uso del Sitio o de la Aplicación cuando una operación o servicio se concrete y dicho Usuario sea quien haya realizado el Servicio a favor de otro Usuario.',

    '9.2.- La Empresa se reserva el derecho de tomar las medidas judiciales y extrajudiciales que estime pertinentes para obtener el pago del monto debido.',

    '9.3.- La Empresa se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes en el Sitio o sus Aplicaciones en cualquier momento, lo cual será notificado debidamente a los Usuarios, a través de notificación electrónica ya sea directamente publicado como aviso en el Sitio o en el perfil del Usuario. Sin embargo, La empresa y el Sitio podrán modificar temporalmente las tarifas por sus servicios por razón de promociones, siendo efectivas estas modificaciones cuando se haga pública la promoción o se realice el anuncio respectivo.',

    '9.4.- Tal como lo establece la normativa fiscal vigente en la República Mexicana, el Usuario en Búsqueda de un Servicio deberá exigir factura o ticket al Usuario Prestador de Servicios, si así lo quisiere, como comprobante de la operación.',

    'X.- Política de cookies',

    '10.1.- Las Partes convienen en que este Contrato será regido e interpretado de conformidad con el Código Civil y vigente para el Estado de Nuevo León, y demás leyes y reglamentos aplicables. Para todas las cuestiones pertinentes a la interpretación, cumplimiento y aplicación de este Contrato, las Partes en este acto expresamente se someten a la jurisdicción de los Tribunales competentes del fuero común con residencia en la Ciudad de Monterrey, Estado de Nuevo León de los Estados Unidos Mexicanos, renunciando expresamente a cualquier otra jurisdicción que en un futuro pudiera corresponderles por cualquier razón.',

    'XI.- Duración',

    '11.1.- La duración del presente Contrato será de manera indefinida, reservándose la Empresa el derecho, a su exclusiva discreción, mediante simple comunicación electrónica dirigida al perfil o al correo electrónico proporcionado por el Usuario a la Empresa a través del Sitio o de sus respectivas Aplicaciones para: terminar definitivamente el presente Contrato en cualquier momento y sin responsabilidad para la Empresa.',

    'XII.- Ausencia de vicios',

    '12.1.- Las Partes en este acto reconocen que los anteriores acuerdos no conlleva lesión, coerción, violencia, mala fe o intimidación alguna entre las Partes y que ninguna de ellas ha abusado de la ignorancia, miseria o inexperiencia de la otra Parte y por lo tanto, en este acto renuncian a cualquier derecho que tuvieran para rescindir o anular el presente Arrendamiento en los términos del artículo 1709 del Código Civil para el Estado de Nuevo León y su correlativo en el Código Civil Federal.',

    'XIII.- Divisibilidad y acuerdo total',

    '13.1.- Si cualquier término o disposición de este Contrato fuese en cualquier medida declarada ilegal, inválida, nula o no ejecutable, las disposiciones restantes del presente Contrato no serán afectadas o perjudicadas, y todos los otros términos y disposiciones del presente permanecerán con total vigencia y efecto en la medida más amplia permitida por ley.',

    '13.2.- El presente Contrato representa el acuerdo total y final entre las Partes en lo que respecta al contenido y objeto del mismo y supercede y substituye a cualquier otro acuerdo, convenio o contrato, ya sea tácito o expreso anterior al presente.',

    'Última actualización: 02/03/2019',

    'El presente contrato (el “Contrato”) especifica y describe los términos y condiciones generales (en lo sucesivo los “Términos y Condiciones”) aplicables al uso del sitio web bajo el dominio denominado www.triangulo.app (en lo sucesivo el “Sitio”) perteneciente a la empresa denominada.'
  ]
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
