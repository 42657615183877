import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Styles.module.css';
import { CheckBox, Text } from '..';

const Component = (props) => {
  const {
    classes,
    users,
    onClick
  } = props;

  const classNameRoot = classNames(
    styles.root,
    classes.root
  );

  const handleOnClickCheckBox = (id) => {
    if (onClick) onClick(id);
  };

  return (
    <div className={classNameRoot}>
      {
        users.map(({
          id, name, checked, role_id
        }) => (
          <div
            key={id}
            className={styles.box}
            onClick={() => handleOnClickCheckBox(id)}
          >
            <CheckBox classes={{ root: styles['box-checkbox'] }} checked={checked} />
            <Text text={name} variant={role_id === 1 ? 'bold' : 'body'} />
          </div>
        ))
        }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  users: PropTypes.arrayOf(PropTypes.array),
  onClick: PropTypes.func
};

const defaultProps = {
  classes: {},
  users: [],
  onClick: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
