import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Styles.module.css';
import { FormsLayout } from '../../../layouts';
import { Form, Button } from '../../../ui';

// Actions...
import { passwordRecover as passwordRecoverAction } from '../../../../store/actions';

const Component = (props) => {
  const { passwordRecover } = props;
  const history = useHistory();

  return (
    <div className={styles.root}>
      <FormsLayout>
        <div className={styles.column}>
          <Button classes={{ root: styles['button-back'] }} text="Regresar" variant="simple" onClick={() => history.push('/')} />
          <Form type="password-recover" action={passwordRecover} />
        </div>
      </FormsLayout>
    </div>
  );
};

const propTypes = {
  passwordRecover: PropTypes.func
};

const defaultProps = {
  passwordRecover: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  passwordRecover: passwordRecoverAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
