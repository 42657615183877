import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, memo, useEffect } from 'react';

import {
  Form,
  ImagesToolGrid,
  Divider,
  Button,
  Text,
  Attributes,
  UsersList
} from '../../../ui';
import { validateValues, scrollTop, safeGet } from '../../../../utils/helpers';
import styles from './Styles.module.css';

// Actions...
import {
  getUsers as getUsersAction,
  postItem as postItemAction
} from '../../../../store/actions';

const Component = (props) => {
  const {
    classes,
    postItem,
    getUsers,
    data
  } = props;

  const className = classNames(
    styles.root,
    classes.root
  );

  const allUsers = () => safeGet(data, 'data', []);
  const history = useHistory();

  const [productValues, setProductValues] = useState({});
  const [imagesValues, setImagesValues] = useState({ image: ['test'] });
  const [detailsValues, setDetailsValues] = useState([]);
  const [extrasValues, setExtrasValues] = useState({});
  const [legalValues, setLegalValues] = useState({});
  const [users, setUsers] = useState([]);
  const [userIds, setUserIds] = useState('');

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.fetching === false && data.data === undefined) {
      setTimeout(() => {
        history.push('/');
      }, 500);
    } else setUsers(allUsers());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSetProductValues = (e) => {
    setProductValues(e);
  };

  const handleSetExtrasValues = (e) => {
    setExtrasValues(e);
  };

  const handleSetLegalValues = (e) => {
    setLegalValues(e);
  };

  const updateImagesValues = (newImages) => {
    const images = newImages.map(({ file }) => file);
    setImagesValues({ image: images });
  };

  const findCheckedUsers = (usersUpdated) => {
    let usersChecked = [];
    usersUpdated.forEach((user) => {
      if (user.checked) {
        usersChecked = [
          ...usersChecked,
          user.id
        ];
      }
    });
    setUserIds(
      `${usersChecked.sort().join(',')}`
    );
  };

  const handleOnClickCheckBox = (id) => {
    const usersUpdated = [
      ...users
    ];
    const index = users.findIndex((x) => x.id === id);
    usersUpdated[index] = {
      ...usersUpdated[index],
      checked: !usersUpdated[index].checked
    };
    setUsers(usersUpdated);
    findCheckedUsers(usersUpdated);
  };

  const handleAction = () => {
    const values = {
      ...{
        user_ids: {
          value: userIds
        }
      },
      ...productValues,
      ...imagesValues,
      ...{
        details: detailsValues
      },
      ...extrasValues,
      ...legalValues
    };

    const validateAllInputs = () => {
      const validateProductValues = validateValues(productValues, setProductValues);
      const validateextrasValues = validateValues(extrasValues, setExtrasValues);
      const validateLegalValues = validateValues(legalValues, setLegalValues);
      return (
        validateProductValues
        && validateextrasValues
        && validateLegalValues
      );
    };

    if (validateAllInputs()) {
      const payload = {};
      Object.keys(values).forEach((value) => {
        if (values[value] instanceof Array) {
          payload[value] = values[value];
        } else {
          payload[value] = values[value].value;
        }
      });
      postItem({ payload, history });
    } else scrollTop();
  };

  return (
    <div className={className}>
      <Text classes={{ root: styles.title }} text="Nueva propiedad" variant="h2" />
      <Form type="product" setValuesCallBack={handleSetProductValues} />
      <Divider type="withoutMargins" />
      <ImagesToolGrid updateImagesCallback={updateImagesValues} title="Imagenes" />
      <Divider />
      <Attributes details={detailsValues} updateDetails={setDetailsValues} title="Detalles" />
      <Divider />
      <Form type="extras" setValuesCallBack={handleSetExtrasValues} />
      <Divider type="withoutTopMargin" />
      <Form type="legal" setValuesCallBack={handleSetLegalValues} />
      <Text classes={{ root: styles.title }} text="Asignar" variant="h2" />
      <UsersList users={users} onClick={handleOnClickCheckBox} />
      <Divider />
      <Button classes={{ root: styles.button }} text="Publicar" onClick={handleAction} />
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  postItem: PropTypes.func,
  getUsers: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  classes: {},
  postItem: null,
  getUsers: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.users
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  postItem: postItemAction,
  getUsers: getUsersAction

}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default memo(ComponentConnected);
