import React from 'react';
import PropTypes from 'prop-types';

import styles from './Styles.module.css';
import { Header, Footer } from '..';

const Component = (props) => {
  const { children } = props;
  return (
    <div className={styles.root}>
      <div className={styles.formColum}>
        <Header />
        <div className={styles.main}>
          {children}
        </div>
        <Footer />
      </div>
      <div className={styles.imageColumn}>
        <div className={styles.image} />
      </div>
    </div>
  );
};

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
