import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from '..';

import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    text,
    type
  } = props;
  const classNameRoot = classNames(
    styles.root,
    classes.root,
    styles[type],
    classes[type]
  );
  return (
    <div className={classNameRoot}>
      <Text text={text} variant="input" color="white" />
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  type: PropTypes.string
};

const defaultProps = {
  classes: {},
  text: '',
  type: 'normal'
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
