import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactIdswiper from 'react-id-swiper';

const Component = (props) => {
  const {
    params,
    children
  } = props;

  return <ReactIdswiper {...params}>{children}</ReactIdswiper>;
};

const propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node
};

const defaultProps = {
  params: [],
  children: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default memo(Component);
