import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Styles.module.css';
import { Text } from '..';

const Component = (props) => {
  const {
    classes,
    children,
    onClick,
    variant,
    state,
    text,
    ...others
  } = props;

  const classNameRoot = classNames(
    styles.root,
    styles[`variant-${variant}`],
    styles[`state-${state}`],
    styles[`variant-${variant}-state-${state}`],
    classes.root,
    classes[`variant-${variant}`],
    classes[`state-${state}`],
    classes[`variant-${variant}-state-${state}`]
  );
  const textClasses = {
    'color-white': styles['text-color-white'],
    'color-black': styles['text-color-black']
  };
  const handlerOnClick = (e) => {
    e.preventDefault();
    if (onClick && state !== 'disabled') onClick();
  };

  return (
    <div
      className={classNameRoot}
      onClick={(e) => handlerOnClick(e)}
      {...others}
    >
      { children }
      <Text
        classes={textClasses}
        text={text}
        variant="bold"
        color="white"
      />
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string
};

const defaultProps = {
  classes: {},
  children: null,
  onClick: null,
  variant: 'normal',
  state: 'active',
  text: ''
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
