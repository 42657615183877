import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styles from './Styles.module.css';
import { FormsLayout } from '../../layouts';
import { Form, Button, Text } from '../../ui';

// Actions...
import { register as registerAction } from '../../../store/actions';

const Component = (props) => {
  const { register, data } = props;

  const history = useHistory();

  return (
    <div className={styles.root}>
      <FormsLayout>
        <div className={styles.column}>
          <Button
            classes={{ root: styles['button-back'] }}
            text="Regresar"
            variant="simple"
            onClick={() => history.goBack()}
          />
          {
            data.fetching !== true && <Form type="register" action={register} />
          }
          {
            data.fetching && <Text color="light" text="Registrando..." />
          }
        </div>
      </FormsLayout>
    </div>
  );
};

const propTypes = {
  register: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  register: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.register
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  register: registerAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
