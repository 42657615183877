import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, ComboBox } from '..';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    options,
    selected,
    variant,
    onClick
  } = props;

  const classNameRoot = classNames(
    styles.root,
    classes.root
  );
  const classNameButton = classNames(
    styles.button,
    classes.button
  );

  const getState = (id) => {
    if (selected === id) return 'selected';
    return 'active';
  };

  const handleOnClick = (id) => {
    if (onClick) onClick(id);
  };

  return (
    <div className={classNameRoot}>
      {
        options.map(({ id, value, options: comboOptions }) => {
          if (variant === 'comboBox') {
            return (
              <ComboBox
                key={id}
                classes={{ root: classNameButton }}
                value={value}
                options={comboOptions}
                state={getState(id)}
                onClick={(option) => handleOnClick({ id, option })}
              />
            );
          } return (
            <Button
              key={id}
              classes={{ root: classNameButton }}
              text={value}
              variant="simple"
              state={getState(id)}
              onClick={() => handleOnClick({ id })}
            />
          );
        })
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.number,
  variant: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  classes: {},
  options: [],
  selected: 0,
  variant: 'button',
  onClick: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
