import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getString, validateHtml } from '../../../utils/helpers';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    onClick,
    children,
    childrenPosition,
    font,
    variant,
    color,
    text
  } = props;

  const className = classNames(
    styles.root,
    styles[`variant-${variant}`],
    styles[`font-${font}`],
    styles[`color-${color}`],
    classes.root,
    classes[`variant-${variant}`],
    classes[`font-${font}`],
    classes[`color-${color}`],
    {
      [styles[`children-position-${childrenPosition}`]]: children,
      [classes[`children-position-${childrenPosition}`]]: children
    }
  );

  const classNameHtml = classNames(
    styles.html,
    classes.html,
    {
      [styles[`children-position-${childrenPosition}`]]: children,
      [classes[`children-position-${childrenPosition}`]]: children
    }
  );

  const handleOnClick = () => {
    if (onClick) onClick();
  };

  return (
    !validateHtml(text)
      ? (
        <div
          className={className}
          onClick={() => handleOnClick()}
        >
          { getString(text)}
          {children}
        </div>
      )
      : (
        <div
          className={classNameHtml}
          onClick={() => handleOnClick()}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: text }}
        >
          {children}
        </div>
      )
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  children: PropTypes.node,
  childrenPosition: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  font: PropTypes.string
};

const defaultProps = {
  classes: {},
  onClick: null,
  children: null,
  childrenPosition: 'right',
  variant: 'body',
  color: 'black',
  text: '',
  font: 'Inter'
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
