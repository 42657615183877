import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Form, Button, Options, Text
} from '../../../ui';
import styles from './Styles.module.css';
import {
  profile as profileAction,
  logout as logoutAction,
  updateProfile as updateProfileAction
} from '../../../../store/actions';
import { safeGet } from '../../../../utils/helpers';

const Component = (props) => {
  const {
    data, logout, updateProfile, profile
  } = props;

  const history = useHistory();

  const options = [
    {
      id: 1,
      value: 'Perfil',
      state: 'selected'
    },

    {
      id: 2,
      value: 'Separaciones',
      state: 'active'
    }
  ];

  const [optionSelected, setOptionSelected] = useState(options[0].id);

  useEffect(() => {
    profile({ history });
  }, []);

  useEffect(() => {
    if (
      data.fetching === false
      && (
        data.data === []
        || data.data === undefined
      )
    ) {
      setTimeout(() => {
        // history.push('/')
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (optionSelected === 2) history.push('/settings/separations');
  }, [optionSelected]);

  const handleLogout = () => {
    logout({ history });
  };
  return (
    <div className={styles.root}>
      <div className={styles.options}>
        <Options
          options={options}
          selected={optionSelected}
          className={styles.options}
          onClick={({ id }) => setOptionSelected(id)}
        />
      </div>
      {
        data.fetching
          ? <Text classes={{ root: styles['text-loading'] }} text="Actualizando..." color="light" />
          : safeGet(data, 'data.user', undefined)
        && (
        <Form
          type="profile"
          data={safeGet(data, 'data.user', {})}
          action={
            (payload) => updateProfile({ payload, history })
          }
        />
        )
      }
      <Button classes={{ root: styles['button-close-sesion'] }} text="Cerrar sesión" variant="simple" onClick={() => handleLogout()} />
    </div>
  );
};

const propTypes = {
  profile: PropTypes.func,
  logout: PropTypes.func,
  updateProfile: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  profile: null,
  logout: null,
  updateProfile: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.login
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  profile: profileAction,
  logout: logoutAction,
  updateProfile: updateProfileAction

}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
