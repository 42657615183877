const certificates = [
  {
    id: true,
    value: 'Sí'
  },
  {
    id: false,
    value: 'No'
  }
];

export default certificates;
