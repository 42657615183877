import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { X } from 'react-feather';

import { Button, InputBox, Text } from '..';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    details,
    updateDetails,
    title
  } = props;

  const [attributes, setAttributes] = useState(details);

  const classNameRoot = classNames(
    styles.root,
    classes.root
  );

  useEffect(() => {
    setAttributes(details);
  }, [details]);

  const handleOnClickAddAttribute = () => {
    const nextId = attributes.length > 0
      ? attributes[attributes.length - 1].id + 1
      : 0;

    const newAttributes = [
      ...attributes,
      {
        id: nextId,
        title: '',
        detail: ''
      }
    ];
    setAttributes(newAttributes);
    if (updateDetails) {
      updateDetails(newAttributes);
    }
  };

  const handleOnChangeKey = (id, value) => {
    const elementsIndex = attributes.findIndex((x) => x.id === id);
    const newAttributes = [
      ...attributes
    ];
    newAttributes[elementsIndex] = {
      ...newAttributes[elementsIndex],
      title: value
    };
    setAttributes(newAttributes);
    if (updateDetails) {
      updateDetails(newAttributes);
    }
  };

  const handleOnChangeValue = (id, value) => {
    const elementsIndex = attributes.findIndex((x) => x.id === id);
    const newAttributes = [
      ...attributes
    ];
    newAttributes[elementsIndex] = {
      ...newAttributes[elementsIndex],
      detail: value
    };
    setAttributes(newAttributes);
    if (updateDetails) {
      updateDetails(newAttributes);
    }
  };

  const handleOnClickDeleteAttribute = (id) => {
    const filtered = attributes.filter((x) => x.id !== id);
    setAttributes(filtered);
    if (updateDetails) {
      updateDetails(filtered);
    }
  };

  return (
    <div className={classNameRoot}>
      {
        (title && attributes.length > 0) && <Text classes={{ root: styles['text-title'] }} text={title} variant="h2" />
      }
      {
        attributes
        && (
          <div className={styles['attributes-rows']}>
            {
              attributes.map(({ id, title: inputTitle, detail }) => (
                <div key={`attribute-${id}`} className={styles['attribute-row']}>
                  <InputBox
                    classes={{
                      root:
                        styles.inputbox
                    }}
                    size
                    title="Título"
                    value={inputTitle}
                    placeholder="Título..."
                    onChange={(newValue) => handleOnChangeKey(id, newValue)}
                  />
                  <InputBox
                    classes={{
                      root:
                        styles.inputbox
                    }}
                    size
                    title="Detalle"
                    value={detail}
                    placeholder="Detalle..."
                    onChange={(newValue) => handleOnChangeValue(id, newValue)}
                  />
                  <X
                    className={styles['attribute-icon']}
                    onClick={() => handleOnClickDeleteAttribute(id)}
                  />
                </div>
              ))
            }
          </div>
        )
      }
      <Button
        classes={{ root: styles['button-add-a ttribute'] }}
        text="Agregar detalle"
        variant="simple"
        onClick={() => handleOnClickAddAttribute()}
      />
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  details: PropTypes.arrayOf(PropTypes.object),
  updateDetails: PropTypes.func,
  title: PropTypes.string
};

const defaultProps = {
  classes: {},
  details: [],
  updateDetails: null,
  title: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
