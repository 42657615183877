const types = [
  {
    id: 1,
    value: 'Venta'
  },
  {
    id: 2,
    value: 'Preventa'
  }
];

export default types;
