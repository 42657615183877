import React from 'react';

import styles from './Styles.module.css';
import logo from '../../../../media/logo.svg';

const Component = () => (
  <div className={styles.root}>
    <img src={logo} className={styles.logo} alt="logo" />
  </div>
);

export default Component;
