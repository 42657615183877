import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Button, Menu } from '../../../ui';
import logo from '../../../../media/logo.svg';
import styles from './Styles.module.css';
// import { safeGet } from '../../../../utils/helpers';

const Component = (props) => {
  const { data } = props;
  const history = useHistory();
  const location = useLocation();

  const [pathname, setPathname] = useState(history.location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    // if (safeGet(data, 'user.data.role_id') === 1) history.goBack();
  }, [data]);

  const menuItems = [
    {
      id: 'Cuenta',
      text: 'Cuenta',
      onClick: () => history.push('/settings/profile')
    }
  ];

  return (
    <div className={styles.root}>
      <Button
        classes={{ root: styles.breadcrumb }}
        text={pathname === '/catalog' ? 'Catálogo' : 'Regresar'}
        state={pathname === '/catalog' ? 'disabled' : 'active'}
        variant="simple"
        onClick={() => {
          if (pathname !== '/catalog') history.push('/catalog');
        }}
      />
      <img src={logo} className={styles.logo} alt="logo" />
      <Menu classes={{ root: styles.menu }} items={menuItems} />
    </div>
  );
};

const propTypes = {
  data: PropTypes.objectOf(PropTypes.objectOf)
};

const defaultProps = {
  data: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.login
});

const ComponentConnected = connect(
  mapStateToProps
)(Component);

export default ComponentConnected;
