import { combineReducers } from 'redux';
import { safeGet } from '../../utils/helpers';
import {
  ADD_ERROR_MESSAGE,
  ADD_MESSAGE,
  ADMIN_ITEMS,
  DELETE_ERROR_MESSAGE,
  DELETE_MESSAGE,
  FETCH_CHANGE_USER_STATUS,
  FETCH_DELETE_ITEM,
  FETCH_GET_ADMIN_ITEM,
  FETCH_GET_ADMIN_ITEMS,
  FETCH_GET_ITEM,
  FETCH_GET_ITEMS,
  FETCH_GET_PROFILE,
  FETCH_GET_USERS,
  FETCH_UPDATE_USER,
  FETCH_UPDATE_PROFILE,
  FETCH_LOGIN,
  FETCH_PASSWORD_RECOVER,
  FETCH_POST_ITEM,
  FETCH_REGISTER,
  FETCH_UPDATE_ITEM,
  ITEM,
  PASSWORD_TOKEN,
  PASSWORD_RESET,
  FETCH_PASSWORD_RESET,
  ADMIN_ITEM,
  ITEMS,
  LOGIN,
  LOGOUT,
  PASSWORD_RECOVER,
  PROFILE,
  REGISTER,
  USERS,
  FETCH_CHECKOUT,
  CLEAR_CHECKOUT,
  CHECKOUT,
  FETCH_PROFILE_SEPARATIONS,
  PROFILE_SEPARATIONS
} from '../actions';
import statesIds from '../../constants/stateIds';
import certificateIds from '../../constants/certificateIds';
import typeIds from '../../constants/typeIds';

const comboIds = {
  certificated: certificateIds,
  type_id: typeIds,
  state_id: statesIds
};

const initialState = {
};

function messages(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        ...payload
      };
    case DELETE_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      const {
        [payload]: deleted,
        ...others
      } = state;
      return {
        ...others
      };
    default:
      return state;
  }
}

function errorMessages(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case ADD_ERROR_MESSAGE:
      return {
        ...state,
        ...payload
      };
    case DELETE_ERROR_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      const {
        [payload]: deleted,
        ...others
      } = state;
      return {
        ...others
      };
    default:
      return state;
  }
}

function register(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_REGISTER:
      return {
        ...state,
        fetching: true
      };
    case REGISTER:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    default:
      return state;
  }
}

function login(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_LOGIN:
    case FETCH_GET_PROFILE:
    case FETCH_UPDATE_PROFILE:
    case FETCH_PROFILE_SEPARATIONS:
      return {
        ...state,
        fetching: true
      };
    case LOGIN:
    case PROFILE:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    case PROFILE_SEPARATIONS:
      return {
        ...state,
        separations: {
          ...payload.data
        },
        fetching: false
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

function passwordRecover(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_PASSWORD_RECOVER:
    case FETCH_PASSWORD_RESET:
      return {
        ...state,
        fetching: true
      };
    case PASSWORD_RECOVER:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    case PASSWORD_TOKEN:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    case PASSWORD_RESET:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
}

function users(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_GET_USERS:
    case FETCH_CHANGE_USER_STATUS:
    case FETCH_UPDATE_USER:
      return {
        ...state,
        fetching: true
      };
    case USERS:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    default:
      return state;
  }
}

const mergeItems = (state, payload) => {
  const payloadUpdated = {
    ...payload
  };
  const stateItems = safeGet(state, 'data.data', []);
  const payloadItems = safeGet(payload, 'data.data', []);
  payloadUpdated.data.data = [
    ...stateItems,
    ...payloadItems
  ];
  return payloadUpdated;
};

function items(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_GET_ITEMS:
    case FETCH_GET_ADMIN_ITEMS:
    case FETCH_POST_ITEM:
    case FETCH_UPDATE_ITEM:
    case FETCH_DELETE_ITEM:
      return {
        ...state,
        fetching: true
      };
    case ITEMS:
      return {
        ...state,
        ...payload,
        ...mergeItems(state, payload),
        fetching: false
      };
    case ADMIN_ITEMS:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    default:
      return state;
  }
}

const fixItemData = (payload) => {
  const data = {
    ...payload.data
  };
  Object.keys(comboIds).forEach((id) => {
    data[id] = comboIds[id].find((x) => x.id === data[id]).value;
  });

  return {
    ...payload,
    ...{
      data: {
        ...data
      }
    }
  };
};

function item(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_GET_ITEM:
    case FETCH_GET_ADMIN_ITEM:
      return {
        ...state,
        fetching: true
      };
    case ITEM:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    case ADMIN_ITEM:
      return {
        ...state,
        ...fixItemData(payload),
        fetching: false
      };
    default:
      return state;
  }
}

function checkout(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_CHECKOUT:
      return {
        ...state,
        fetching: true
      };
    case CLEAR_CHECKOUT:
      return {
        fetching: false
      };
    case CHECKOUT:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    default:
      return state;
  }
}

const reducers = combineReducers({
  messages,
  errorMessages,
  register,
  login,
  passwordRecover,
  users,
  items,
  item,
  checkout
});

export default reducers;
