import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Styles.module.css';
import {
  InputBox, Button, Text, CheckBox
} from '..';
import forms from '../../../constants/forms';
import { validateValues } from '../../../utils/helpers';
import visa from '../../../media/visa-color.svg';
import mastercard from '../../../media/mastercard-color.svg';
import americanexpress from '../../../media/americanexpress-color.svg';

const Component = (props) => {
  const {
    classes,
    type,
    data,
    action,
    buttonText,
    setValuesCallBack
  } = props;

  const {
    inputs, button, title: form_title
  } = forms[type];

  const [values, setValues] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [cardType, setCardType] = useState('unknown');

  useEffect(() => {
    const newValues = {};
    inputs.forEach((input) => {
      newValues[input.id] = { ...input };
    });
    if (data) {
      Object.keys(data).forEach((key) => {
        if (newValues[key] && data[key] !== null) {
          newValues[key].value = data[key].toString();
        }
      });
    }
    setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (setValuesCallBack) setValuesCallBack(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const className = classNames(
    styles.root,
    classes.root
  );

  const fullForms = [
    'product',
    'details',
    'extras',
    'legal',
    'edit-product',
    'edit-details',
    'edit-extras',
    'edit-legal'
  ];

  const inputBoxsClassName = classNames(
    styles.inputBoxs,
    classes.inputBoxs,
    {
      [styles.inputBoxsCheckout]: type === 'checkout',
      [classes.inputBoxsCheckout]: type === 'checkout',
      [styles.inputBoxsAddProduct]: fullForms.includes(type),
      [classes.inputBoxsAddProduct]: fullForms.includes(type)
    }
  );

  const inputClassName = (size) => classNames(
    {
      [styles[`input-${size}`]]: fullForms.includes(type),
      [classes[`input-${size}`]]: fullForms.includes(type)
    }
  );

  const buttonClassName = (size) => classNames(
    styles.button,
    classes.button,
    {
      [styles[`button-${size}`]]: fullForms.includes(type),
      [classes[`button-${size}`]]: fullForms.includes(type)
    }
  );

  const classNameCard = (card) => classNames(
    styles['credit-card'],
    classes['credit-card'],
    {
      [styles['credit-card-hide']]: cardType !== 'unknown' && card !== cardType,
      [classes['credit-card-hide']]: cardType !== 'unknown' && card !== cardType
    }
  );

  const handleOnChange = (key, value) => {
    let newValues = {};
    newValues = {
      ...values
    };
    newValues[key].value = value;
    newValues[key].error = null;
    setValues(newValues);
  };

  const handleAction = () => {
    if (validateValues(values, setValues) && action) {
      const payload = {};
      Object.keys(values).forEach((value) => {
        payload[value] = values[value].value;
      });
      action(payload);
    }
  };

  const handleOnPressEnter = () => {
    handleAction();
  };

  return (
    <div className={className}>
      {
        form_title && (
          <>
            <Text classes={{ root: styles.form_title }} text={form_title} variant="h2" />
          </>
        )
      }
      {
        inputs
        && (
          <>
            <div className={inputBoxsClassName}>
              {
                Object.keys(values).map((id) => {
                  const {
                    title,
                    required,
                    variant,
                    value,
                    error,
                    placeholder,
                    size,
                    comboOptions,
                    link
                  } = values[id];
                  return (
                    <InputBox
                      key={id}
                      classes={{ root: inputClassName(size) }}
                      required={required}
                      variant={variant}
                      value={value}
                      comboOptions={comboOptions}
                      errorMessage={error}
                      title={title}
                      placeholder={placeholder}
                      state="normal"
                      link={link}
                      onPressEnter={handleOnPressEnter}
                      onChange={(e) => handleOnChange(id, e)}
                      setCardType={setCardType}
                    >
                      {
                      id === 'card-number'
                      && (
                        <div className={styles['credit-cards']}>
                          <img className={classNameCard('visa')} src={visa} alt="visa" />
                          <img className={classNameCard('mastercard')} src={mastercard} alt="master card" />
                          <img className={classNameCard('amex')} src={americanexpress} alt="american express" />
                        </div>
                      )
                      }
                    </InputBox>
                  );
                })
              }
            </div>
            {
              type === 'checkout'
              && (
                <div className={styles.terms} onClick={() => setAcceptTerms(!acceptTerms)}>
                  <CheckBox classes={{ root: styles['checkBox-terms'] }} checked={acceptTerms} />
                  <div className={styles.termsTexts}>
                    <Text
                      classes={{ root: styles['text-terms'] }}
                      text="He leído y acepto los"
                      color="gray"
                    />
                    <Text
                      classes={{ root: styles['text-terms'] }}
                      text="Términos y Condiciones"
                      color="black"
                      variant="bold"
                    />
                    <Text
                      classes={{ root: styles['text-terms'] }}
                      text="y"
                      color="gray"
                    />
                    <Text
                      classes={{ root: styles['text-terms'] }}
                      text="las"
                      color="gray"
                    />
                    <Text
                      text="Políticas de privacidad"
                      color="black"
                      variant="bold"
                    />
                  </div>
                </div>
              )
            }
            {
              button && (
                <Button
                  classes={{ root: buttonClassName(button.size) }}
                  text={buttonText || button.text}
                  onClick={() => handleAction()}
                  state={!acceptTerms && type === 'checkout' ? 'disabled' : 'active'}
                />
              )
            }
          </>
        )
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.object),
  buttonText: PropTypes.string,
  action: PropTypes.func,
  callBack: PropTypes.func,
  setValuesCallBack: PropTypes.func
};

const defaultProps = {
  classes: {},
  type: 'login',
  data: null,
  buttonText: null,
  action: null,
  callBack: null,
  setValuesCallBack: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default memo(Component);
