import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Home } from 'react-feather';
import React, { useEffect, useState } from 'react';

import {
  Form, Divider, PopupMenu, Button
} from '../../../ui';
import constants from '../../../../constants/constants';
import { Header } from '../../FormsLayout';
import styles from './Styles.module.css';
import { safeGet, toCurrency } from '../../../../utils/helpers';
import {
  checkout as checkoutAction,
  clearCheckout as clearCheckoutAction
} from '../../../../store/actions';

const Component = (props) => {
  const {
    children,
    data,
    userData,
    checkout,
    checkoutData,
    clearCheckout
  } = props;

  const [displayPopupMenu, setDisplayPopupMenu] = useState(false);
  const [conektaToken, setConektaToken] = useState(undefined);
  const history = useHistory();
  const popupMenuOptions = [
    {
      text: 'Continuar',
      action: () => {
        setDisplayPopupMenu(false);
        clearCheckout();
        history.push('/settings/separations');
      },
      variant: 'normal'
    }
  ];

  useEffect(() => {
    if (conektaToken !== undefined) {
      const payload = {
        item_id: safeGet(data, 'data.id'),
        user_id: safeGet(userData, 'data.user.id'),
        token_card: conektaToken // 'tok_test_visa_4242'
      };
      checkout(payload);
    }
  }, [conektaToken]);

  useEffect(() => {
    if (checkoutData.success) setDisplayPopupMenu(true);
  }, [checkoutData]);

  const handleResponseConekta = (e) => {
    if (e.object === 'token') setConektaToken(e.id);
  };

  const handleOnPay = (e) => {
    const tokenParams = {
      card: {
        number: e['card-number'],
        name: e.name,
        exp_year: e['month-year'].substring(2, 6),
        exp_month: e['month-year'].substring(0, 2),
        cvc: e.cvc,
        address: {
          street1: 'Calle 123 Int 404',
          street2: 'Col. Condesa',
          city: 'Ciudad de Mexico',
          state: 'Distrito Federal',
          zip: '12345',
          country: 'Mexico'
        }
      }
    };
    // eslint-disable-next-line no-undef
    Conekta.setPublicKey(constants.conektaApiKey);
    // eslint-disable-next-line no-undef
    Conekta.Token.create(tokenParams, handleResponseConekta);
  };

  const buttonText = `Pagar ${toCurrency(safeGet(data, 'data.separation_price', 0))}`;

  return (
    <div className={styles.root}>
      {
        displayPopupMenu && (
          <PopupMenu
            icon={<Home />}
            title="Felicidades"
            description="Aenean pellentesque felis odio, fringilla scelerisque leo dignissim at orbi convallis sit amet sem."
            options={popupMenuOptions}
          />
        )
      }
      <div className={styles.main}>
        <div className={styles.formColumn}>
          <Header />
          <div className={styles.content}>
            <Button
              classes={{ root: styles['button-back'] }}
              text="Regresar"
              variant="simple"
              onClick={() => history.push(`/details/${safeGet(data, 'data.id')}`)}
            />
            <Form type="checkout" action={handleOnPay} buttonText={buttonText} />
          </div>
          <Divider type="hidden" />
        </div>
        <div className={styles.product}>
          { children }
        </div>
      </div>
      <Divider type="hidden" />
    </div>
  );
};

const propTypes = {
  children: PropTypes.node,
  data: PropTypes.objectOf(PropTypes.object),
  userData: PropTypes.objectOf(PropTypes.object),
  checkoutData: PropTypes.objectOf(PropTypes.object),
  checkout: PropTypes.func,
  clearCheckout: PropTypes.func
};

const defaultProps = {
  children: null,
  data: null,
  userData: null,
  checkoutData: null,
  checkout: null,
  clearCheckout: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.item,
  userData: state.login,
  checkoutData: state.checkout
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  checkout: checkoutAction,
  clearCheckout: clearCheckoutAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
