import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import styles from './Styles.module.css';
import { getPathId } from '../../../../utils/helpers';
import { Form } from '../../../ui';
import {
  getUsers as getUsersAction,
  updateUser as updateUserAction,
  changeUserStatus as changeUserStatusAction
} from '../../../../store/actions';

const Component = (props) => {
  const { getUsers, updateUser, data } = props;

  const history = useHistory();

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.fetching === false && data.data === undefined) {
      setTimeout(() => {
        // history.push('/')
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const findId = () => {
    const users = data && data.data ? data.data : [];
    const index = users.findIndex((x) => x.id.toString() === getPathId(history));
    return index;
  };

  return (
    <div className={styles.root}>
      {
        findId() > 0
        && (
          <Form
            type="profile-admin"
            data={data.data[findId()]}
            action={
              (payload) => updateUser({ payload, history })
            }
          />
        )
      }
    </div>
  );
};

const propTypes = {
  getUsers: PropTypes.func,
  updateUser: PropTypes.func,
  changeUserStatus: PropTypes.func,
  data: PropTypes.shape({
    fetching: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.objectOf
    )
  })
};

const defaultProps = {
  getUsers: null,
  updateUser: null,
  changeUserStatus: null,
  data: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.users
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getUsers: getUsersAction,
  updateUser: updateUserAction,
  changeUserStatus: changeUserStatusAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
