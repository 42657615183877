import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map, GoogleApiWrapper } from 'google-maps-react';

import constants from '../../../constants/constants';

const Component = (props) => {
  const { google } = props;
  return (
    <Map
      google={google}
      zoom={14}
      initialCenter={
          {
            lat: -1.2884,
            lng: 36.8233
          }
        }
    />
  );
};

const propTypes = {
  google: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  google: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default memo(
  GoogleApiWrapper(
    {
      apiKey: constants.googleApiKey
    }
  )(Component)
);
