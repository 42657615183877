import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from './Styles.module.css';
import { Text } from '../../../ui';

const Component = () => {
  const history = useHistory();

  return (

    <div className={styles.root}>
      <Text
        classes={{
          root:
            styles.text
        }}
        text="Términos"
        variant="footer"
        color="gray"
        onClick={() => history.push('/terms')}
      />
      <Text
        classes={{
          root:
            styles.text
        }}
        text="Privacidad"
        variant="footer"
        color="gray"
        onClick={() => history.push('/privacy')}
      />
    </div>
  );
};

export default Component;
