import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Styles.module.css';

const Component = (props) => {
  const { classes, variant } = props;

  const classNameRoot = classNames(
    styles.root,
    classes.root,
    styles[`root-${variant}`],
    classes[`root-${variant}`]

  );

  const classNameLoading = classNames(
    styles[`loading-${variant}`],
    classes[`loading-${variant}`]
  );

  return (
    <div className={classNameRoot}>
      <div className={classNameLoading} />
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  variant: PropTypes.string
};

const defaultProps = {
  classes: {},
  variant: 'circle'
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default memo(Component);
