import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DollarSign, X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Text,
  PopupMenu
} from '../../../ui';
import {
  deleteItem as deleteItemAction,
  getAdminItems as getAdminItemsAction
} from '../../../../store/actions';
import { safeGet, toCurrency } from '../../../../utils/helpers';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    data,
    getAdminItems,
    deleteItem
  } = props;

  const [displayPopupMenu, setDisplayPopupMenu] = useState(false);
  const [deleteItemSelected, setDeleteItemSelected] = useState(0);
  const history = useHistory();

  const popupMenuOptions = [
    {
      text: 'Cancelar',
      action: () => {
        setDisplayPopupMenu(false);
      }
    },
    {
      text: 'Eliminar',
      action: () => {
        deleteItem(deleteItemSelected);
        setDisplayPopupMenu(false);
      }
    }
  ];

  const titles = [
    'Titulo',
    'Precio',
    'Pagado',
    'Opciones'
  ];

  const keys = [
    'title',
    'sale_price',
    'status.id'
  ];

  useEffect(() => {
    getAdminItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      data.fetching === false
      && safeGet(data, 'data', []) === []
    ) {
      setTimeout(() => {
        // history.push('/')
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOnClickUpdateItem = (id) => {
    window.open(`/backoffice/product/${id}`);
  };

  const handleOnClickDeleteItem = (id) => {
    setDeleteItemSelected(id);
    setDisplayPopupMenu(true);
  };

  return (
    <div className={styles.root}>
      {
        displayPopupMenu && (
          <PopupMenu
            description="¿Estás seguro que deseas eliminar este elemento?"
            options={popupMenuOptions}
          />
        )
      }
      <div className={styles.header}>
        <Text text="Catálogo" variant="h2" />
        <Button text="Nuevo Producto" variant="simpleInverted" onClick={() => history.push('/backoffice/product')} />
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          {
            titles.map((key, index) => (
              <div
                key={key}
                id={key}
                className={
                  (index === 0)
                    ? styles.column
                    : (index === 3)
                      ? styles['column-options']
                      : styles['column-center']
                }
              >
                <Text text={key} />
              </div>
            ))
          }
        </div>
        {
          safeGet(data, 'data', []).length > 0
          && safeGet(data, 'data', []).map((item) => {
            const status = safeGet(item, 'item_status.id');
            return (
              <div key={item.id} className={styles.row}>
                {
                keys.map((key, index) => {
                  const value = safeGet(item, key);

                  return (
                    <div
                      key={value}
                      className={index === 0 ? styles.column : styles['column-center']}
                    >
                      {
                        key !== 'status.id'
                          ? (
                            <Text
                              text={
                                key === 'sale_price'
                                  ? toCurrency(value)
                                  : value
                                }
                              variant="body"
                            />
                          )
                          : (
                            (status === 1)
                              ? <X />
                              : <DollarSign />
                          )
                      }
                    </div>
                  );
                })
              }
                <div className={styles['column-options']}>
                  <Button
                    text="Editar"
                    variant="simple"
                    state={(status !== 1) ? 'disabled' : 'active'}
                    onClick={() => handleOnClickUpdateItem(item.id)}
                  />
                  <Button
                    text="Eliminar"
                    variant="simple"
                    state={(status !== 1) ? 'disabled' : 'active'}
                    onClick={() => handleOnClickDeleteItem(item.id)}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

const propTypes = {
  getAdminItems: PropTypes.func,
  deleteItem: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape(
    {
      data: PropTypes.objectOf(PropTypes.object),
      success: PropTypes.bool
    }
  ))
};

const defaultProps = {
  getAdminItems: null,
  deleteItem: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.items
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAdminItems: getAdminItemsAction,
  deleteItem: deleteItemAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
