import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    type
  } = props;
  const classNameRoot = classNames(
    styles.root,
    classes.root,
    styles[type],
    classes[type]
  );
  return (
    <div className={classNameRoot} />
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string
};

const defaultProps = {
  classes: {},
  type: 'normal'
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
