const states = [
  { id: 1, value: 'Aguascalientes' },
  { id: 2, value: 'Baja California' },
  { id: 3, value: 'Baja California Sur' },
  { id: 4, value: 'Campeche' },
  { id: 5, value: 'Chiapas' },
  { id: 6, value: 'Chihuahua' },
  { id: 7, value: 'Coahuila de Zaragoza' },
  { id: 8, value: 'Colima' },
  { id: 9, value: 'Ciudad de México' },
  { id: 10, value: 'Durango' },
  { id: 11, value: 'Guanajuato' },
  { id: 12, value: 'Guerrero' },
  { id: 13, value: 'Hidalgo' },
  { id: 14, value: 'Jalisco' },
  { id: 15, value: 'Estado de Mexico' },
  { id: 16, value: 'Michoacan de Ocampo' },
  { id: 17, value: 'Morelos' },
  { id: 18, value: 'Nayarit' },
  { id: 19, value: 'Nuevo Leon' },
  { id: 20, value: 'Oaxaca' },
  { id: 21, value: 'Puebla' },
  { id: 22, value: 'Queretaro de Arteaga' },
  { id: 23, value: 'Quintana Roo' },
  { id: 24, value: 'San Luis Potosi' },
  { id: 25, value: 'Sinaloa' },
  { id: 26, value: 'Sonora' },
  { id: 27, value: 'Tabasco' },
  { id: 28, value: 'Tamaulipas' },
  { id: 29, value: 'Tlaxcala' },
  { id: 30, value: 'Veracruz de Ignacio de la Llave' },
  { id: 31, value: 'Yucatan' },
  { id: 32, value: 'Zacatecas' }
];

export default states;
