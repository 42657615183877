import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from './Styles.module.css';
import { Text, Menu } from '../../../ui';

const Component = () => {
  const history = useHistory();

  const menuItems = [
    {
      id: 'Terminos',
      text: 'Terminos',
      onClick: () => history.push('/terms')
    },
    {
      id: 'Privacidad',
      text: 'Privacidad',
      onClick: () => history.push('/privacy')
    }
  ];

  return (
    <div className={styles.root}>
      <Text text="Triangulo.app © 2021" color="light" variant="footer" />
      <Menu items={menuItems} withMobile={false} textVariant="footer" textColor="gray" />
    </div>
  );
};

export default Component;
