import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import queryString from 'query-string';

import styles from './Styles.module.css';
import { FormsLayout } from '../../../layouts';
import { Form, Text } from '../../../ui';

// Actions...
import {
  passwordToken as passwordTokenAction,
  passwordReset as passwordResetAction
} from '../../../../store/actions';

const Component = (props) => {
  const { passwordToken, passwordReset, data } = props;
  const history = useHistory();

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const payload = {
      token: params.token
    };
    passwordToken({ payload, history });
  }, []);

  const passwordResetHandler = (e) => {
    const payload = {
      ...e,
      ...{
        token: data.token
      }
    };
    passwordReset({ payload, history });
  };

  return (
    <div className={styles.root}>
      <FormsLayout>
        <div className={styles.column}>
          { data.fetching ? (
            <Text
              text="Validando token..."
              color="light"
            />
          ) : (
            <>
              {
                data.success
                  ? (
                    <>
                      <Text classes={{ root: styles['text-title'] }} text="Cambiar contraseña" variant="h2" />
                      <Form
                        type="password-reset"
                        action={passwordResetHandler}
                        data={data}
                      />
                    </>
                  )
                  : (
                    <Text
                      text="Token invalido..."
                      color="light"
                    />
                  )
              }
            </>
          )}
        </div>
      </FormsLayout>
    </div>
  );
};

const propTypes = {
  passwordToken: PropTypes.func,
  passwordReset: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  passwordToken: null,
  passwordReset: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.passwordRecover
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  passwordToken: passwordTokenAction,
  passwordReset: passwordResetAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
