import { bindActionCreators } from 'redux';
import { Tag } from 'react-feather';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';

import { getPathId, toCurrency } from '../../../utils/helpers';
import styles from './Styles.module.css';
import {
  Button, Divider, Slider, Text
} from '../../ui';

// Actions
import {
  getItem as getItemAction,
  profile,
  profile as profileAction,
  addErrorMessage as addErrorMessageAction,
  deleteErrorMessage as deleteErrorMessageAction
} from '../../../store/actions';

const Component = (props) => {
  const {
    getItem,
    addErrorMessage,
    deleteErrorMessage,
    data,
    user_data
  } = props;

  const history = useHistory();

  const [seeMore, setSeeMore] = useState(false);

  const classNameMore = (hide) => classNames(
    styles.more,
    {
      [styles.hideMore]: hide
    }
  );

  const classNameDescription = classNames(
    styles.descriptionSmall,
    {
      [styles.descriptionFull]: seeMore
    }
  );

  useEffect(() => {
    getItem(getPathId(history));
    profile({ history });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.fetching === false && data.data === undefined) {
      setTimeout(() => {
        history.push('/e404');
      }, 500);
    }
    console.log(data, 'data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (user_data.fetching === false && user_data.data === undefined) {
      setTimeout(() => {
        history.push('/e404');
      }, 500);
    }
    console.log(user_data, 'user_data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_data]);

  const handleOnClickCheckout = () => {
    const { user } = user_data.data;
    if (user.rfc && user.address && user.state_id && user.city_id && user.zip_code) {
      history.push(
        {
          pathname: `/checkout/${data.data.id}`,
          state: {
            getItem: data.data.id
          }
        }
      );
    } else {
      history.push(
        {
          pathname: '/settings/profile'
        }
      );
      addErrorMessage({ user: 'Completa tus datos para continuar.' });
      setTimeout(() => {
        deleteErrorMessage('user');
      }, 3000);
    }
  };

  const handleOnClickDownload = (path) => {
    window.open(path);
  };

  return (
    <div className={styles.root}>
      {
        data.fetching ? (
          <Text
            classes={{ root: styles['text-empty'] }}
            text="Cargando..."
            color="light"
          />
        )
          : data.data ? (
            <>
              <Slider images={data.data.images} />
              <div className={styles.info}>
                <div className={styles.left}>
                  <Text classes={{ root: styles.title }} text={data.data.title} variant="h2" />
                  <Text classes={{ root: styles.subTitle }} text={data.data.subtitle} variant="body" color="gray" />
                  <Text classes={{ root: styles.subTitle }} text={data.data.estado.nombre} variant="body" color="dark" />
                  <Divider />
                  <div className={classNameDescription}>
                    <Text classes={{ root: styles.description }} text={data.data.description} variant="body" color="dark" />
                  </div>
                  <Text
                    classes={{ root: classNameMore(data.data.description.length < 280) }}
                    text={seeMore ? 'Ver menos...' : 'Ver mas...'}
                    variant="bold"
                    onClick={() => setSeeMore(!seeMore)}
                  />
                  <Divider />
                  <div className={styles.attributes}>
                    {
                      data.data.detail.map(({ title, detail }) => (
                        <div key={`${title}-${data.data.id}`} className={styles.attributeRow}>
                          <Text text={title} variant="bold" />
                          <Text text={detail} variant="body" color="dark" />
                        </div>
                      ))
                    }
                  </div>
                  <Divider />
                  <div className={styles.map}>
                    <Iframe
                      width="100%"
                      height="320"
                      id="gmap_canvas"
                      src={data.data.google_maps_url}
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                    />
                  </div>
                  <Button
                    classes={{ root: styles['button-map'] }}
                    text={data.data.button_text}
                    variant="small"
                    onClick={() => { handleOnClickDownload(data.data.button_url); }}
                  />
                  <Divider />
                  <div>
                    <Text
                      classes={{ root: styles.fullDescription }}
                      text={data.data.legal}
                      variant="body"
                      color="gray"
                    />
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.price}>
                    <Text text="Precio real" variant="bold" />
                    <Text classes={{ root: styles.real }} text={toCurrency(data.data.real_price)} variant="h3" color="light" />
                  </div>
                  <div className={styles.price}>
                    <Text text="Precio de venta" variant="bold" />
                    <Text text={toCurrency(data.data.sale_price)} variant="h3" />
                  </div>
                  <div className={styles.price}>
                    <Text text="Separación" variant="bold" />
                    <Text text={toCurrency(data.data.separation_price)} variant="h3" childrenPosition="left">
                      <Tag className={styles.tagIcon} />
                    </Text>
                  </div>
                  <Button classes={{ root: styles.button }} text="Comprar" variant="normal" onClick={() => handleOnClickCheckout()} />
                  <Text
                    text={data.data.short_description}
                    variant="p"
                    color="gray"
                  />
                </div>
              </div>
            </>
          ) : (
            <Text variant="bold" text={data.fetching ? 'Cargando...' : 'Sin coincidencias'} />
          )
      }
    </div>
  );
};

const propTypes = {
  getItem: PropTypes.func,
  addErrorMessage: PropTypes.func,
  deleteErrorMessage: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object),
  user_data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  getItem: null,
  addErrorMessage: null,
  deleteErrorMessage: null,
  data: {},
  user_data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.item,
  user_data: state.login
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getItem: getItemAction,
  profile: profileAction,
  addErrorMessage: addErrorMessageAction,
  deleteErrorMessage: deleteErrorMessageAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
