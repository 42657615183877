import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import styles from './Styles.module.css';
import { FormsLayout } from '../../layouts';
import { Form, Text, Button } from '../../ui';

// Actions...
import { login as loginAction } from '../../../store/actions';

const cookies = new Cookies();

const Component = (props) => {
  const { login } = props;

  const history = useHistory();

  useEffect(() => {
    cookies.remove('token');
  }, []);

  const handleAction = (payload) => {
    login({ payload, history });
  };
  return (
    <div className={styles.root}>
      <FormsLayout>
        <Text classes={{ root: styles['text-title'] }} text="Triángulo Inmobiliaria" variant="h1" />
        <Form type="login" action={handleAction} />
        <Button
          classes={{ root: styles['text-register'] }}
          text="Solicitar Acceso"
          color="light"
          variant="simple"
          onClick={() => history.push('/register')}
        />
      </FormsLayout>
    </div>
  );
};

const propTypes = {
  login: PropTypes.func
};

const defaultProps = {
  login: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  login: loginAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
