import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Text,
  Options,
  ProductBox,
  Button,
  Bullets,
  ComboBox
} from '../../ui';
import { safeGet } from '../../../utils/helpers';
import states from '../../../constants/stateIds';
import styles from './Styles.module.css';
import { getItems as getItemsAction } from '../../../store/actions';

const Component = (props) => {
  const { data, getItems } = props;

  const optionsLeft = [
    {
      id: 1,
      value: 'Ventas'
    },
    {
      id: 2,
      value: 'Preventas'
    },
    {
      id: 3,
      value: 'Todo'
    }
  ];

  const optionsOrderBy = {
    value: 'Ordenar',
    options: [
      {
        id: 1,
        value: 'Mas reciente'
      },
      {
        id: 2,
        value: 'Alfabetico'
      },
      {
        id: 3,
        value: 'Precio Ascendente'
      },
      {
        id: 4,
        value: 'Precio Descendente'
      }
    ]
  };

  const optionsStates = {
    value: 'Estado',
    options: [
      {
        id: 0,
        value: 'Todos'
      },
      ...states
    ]
  };

  const properties = safeGet(data, 'data.data', []);
  const currentPage = safeGet(data, 'data.current_page', 1);
  const lastPage = safeGet(data, 'data.last_page', 1);

  const [optionLeftSelected, setOptionLeftSelected] = useState(optionsLeft[2].id);
  const [orderBy, setOrderBy] = useState({
    id: 0,
    value: optionsOrderBy.value
  });
  const [state, setState] = useState({
    id: 0,
    value: optionsStates.value
  });
  const history = useHistory();

  useEffect(() => {
    if (properties.length === 0) getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      data.fetching === false
      && properties === []
    ) {
      setTimeout(() => {
        history.push('/');
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const sortItems = (items) => {
    const sortedItems = [
      ...items.sort(
        (a, b) => {
          switch (orderBy.id) {
            case 1:
              return a.updated_at.localeCompare(b.updated_at);
            case 2:
              return a.title.localeCompare(b.title);
            case 3:
              return parseFloat(b.sale_price) - parseFloat(a.sale_price);
            case 4:
              return parseFloat(a.sale_price) - parseFloat(b.sale_price);
            default:
              return false;
          }
        }
      )
    ];
    console.log(sortedItems, 'sorted');
    return sortedItems;
  };

  const itemsFilteredByState = (filtered) => {
    if (state.id !== 0) {
      return sortItems([
        ...filtered.filter((x) => safeGet(x, 'estado.id') === state.id)
      ]);
    }
    return sortItems(filtered);
  };

  const itemsFiltered = () => {
    let filtered = [
      ...properties
    ];
    if (properties.length > 0) {
      switch (optionLeftSelected) {
        case 1:
          filtered = [
            ...properties.filter((x) => safeGet(x, 'type_id') === 1)
          ];
          break;
        case 2:
          filtered = [
            ...properties.filter((x) => safeGet(x, 'type_id') === 2)
          ];
          break;
        default:
          filtered = [
            ...properties.filter((x) => safeGet(x, 'type_id') !== 0)
          ];
          break;
      }
    }
    return itemsFilteredByState(filtered);
  };

  const handleOnClickGetMore = () => {
    getItems(currentPage + 1);
  };

  const handleOnClickOptionRight = (option, id) => {
    if (option === 0) {
      setOrderBy({
        id,
        value: optionsOrderBy.options.find((x) => x.id === id).value
      });
    } else {
      setState({
        id,
        value: optionsStates.options.find((x) => x.id === id).value
      });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Options
          classes={{ button: styles['button-options-left'] }}
          selected={optionLeftSelected}
          options={optionsLeft}
          onClick={({ id }) => setOptionLeftSelected(id)}
        />
        <div
          className={styles.options}
        >
          <ComboBox
            options={
              optionsOrderBy.options
            }
            value={orderBy.value}
            onClick={(id) => handleOnClickOptionRight(0, id)}
          />
          <ComboBox
            classes={{ root: styles['comboBox-Statess'] }}
            options={
              optionsStates.options
            }
            value={state.value}
            onClick={(id) => handleOnClickOptionRight(1, id)}
          />
        </div>
      </div>
      {
        itemsFiltered().length > 0 ? (
          <>
            <div className={styles.grid}>
              {
                itemsFiltered().map((item) => (
                  <ProductBox
                    key={item.id}
                    onClick={() => history.push(`/details/${item.id}`)}
                    data={item}
                  />
                ))
              }
            </div>
            {
              (lastPage > currentPage) && (
                data.fetching ? (
                  <Text
                    classes={{ root: styles['text-loading'] }}
                    text="Cargando..."
                    color="light"
                  />
                )
                  : (
                    <Button classes={{ root: styles['button-get-more'] }} variant="simple" onClick={() => handleOnClickGetMore()}>
                      <Bullets />
                    </Button>
                  )
              )
            }
          </>
        ) : (
          <Text text="Sin coincidencias" color="light" />
        )
      }
    </div>
  );
};

const propTypes = {
  getItems: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object)
};

const defaultProps = {
  getItems: null,
  data: []
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.items
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getItems: getItemsAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
