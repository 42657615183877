import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    disabled,
    value,
    placeholder,
    type,
    onChange,
    onFocus,
    onPressEnter
  } = props;

  const [isChanging, setIsChanging] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsChanging(false);
    }, 100);
  }, [value]);

  const className = classNames(
    styles.root,
    classes.root
  );

  const handleOnChange = (data) => {
    if (onChange) onChange(data);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter();
    }
  };

  const handleOnFocus = (e) => {
    if (onFocus) onFocus(e);
  };

  return (
    <div
      className={className}
    >
      {!isChanging && (
        <CKEditor
          editor={ClassicEditor}
          data={value}
          placeholder={placeholder}
          onChange={(_event, editor) => handleOnChange(editor.getData())}
          onFocus={handleOnFocus}
          onKeyDown={handleOnKeyDown}
          disabled={disabled}
          type={type}
          config={{
            toolbar: [
              'bold',
              'italic',
              'numberedList',
              'bulletedList',
              '|',
              'undo',
              'redo'
            ]
          }}
        />
      )}
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onPressEnter: PropTypes.func
};

const defaultProps = {
  classes: {},
  type: 'text',
  disabled: false,
  value: 'test',
  placeholder: 'place holder',
  onChange: null,
  onFocus: null,
  onPressEnter: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
