import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, memo, useEffect } from 'react';

import {
  Form,
  ImagesToolGrid,
  Divider,
  Button,
  Text,
  Attributes,
  UsersList
} from '../../../ui';
import {
  validateValues, scrollTop, safeGet, getPathId
} from '../../../../utils/helpers';
import styles from './Styles.module.css';
import {
  getUsers as getUsersAction,
  getAdminItem as getAdminItemAction,
  deleteImageItem as deleteImageItemAction,
  updateImageIndex as updateImageIndexAction,
  updateItem as updateItemAction
} from '../../../../store/actions';

const Component = (props) => {
  const {
    classes,
    updateItem,
    getUsers,
    getAdminItem,
    deleteImageItem,
    updateImageIndex,
    usersData,
    itemData
  } = props;

  const className = classNames(
    styles.root,
    classes.root
  );

  const history = useHistory();

  const [productValues, setProductValues] = useState({});
  const [imagesValues, setImagesValues] = useState({ image: ['test'] });
  const [imagesCdnValues, setImagesCdnValues] = useState([]);
  const [detailsValues, setDetailsValues] = useState([]);
  const [extrasValues, setExtrasValues] = useState({});
  const [legalValues, setLegalValues] = useState({});
  const [users, setUsers] = useState([]);
  const [item, setItem] = useState({});
  const [userIds, setUserIds] = useState('');
  const [itemId] = useState(getPathId(history));

  const findCheckedUsers = (usersUpdated) => {
    let usersChecked = [];
    usersUpdated.forEach((user) => {
      if (user.checked) {
        usersChecked = [
          ...usersChecked,
          user.id
        ];
      }
    });
    setUserIds(
      `${usersChecked.sort().join(',')}`
    );
  };

  useEffect(() => {
    getUsers();
    getAdminItem(itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usersData.fetching === false && usersData.data === undefined) {
      setTimeout(() => {
        // history.push('/');
      }, 500);
    } else setUsers(safeGet(usersData, 'data', []));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    if (itemData.fetching === false && itemData.data === undefined) {
      setTimeout(() => {
        // history.push('/');
      }, 500);
    } else setItem(safeGet(itemData, 'data'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  useEffect(() => {
    safeGet(item, 'users', []).forEach((user) => {
      const usersUpdated = [
        ...users
      ];
      const index = users.findIndex((x) => x.id === user.user_id);
      usersUpdated[index].checked = true;
      setUsers(usersUpdated);
      findCheckedUsers(usersUpdated);
    });

    let newDetails = [];
    safeGet(item, 'detail', []).forEach((detail) => {
      newDetails = [
        ...newDetails,
        ...[
          {
            id: newDetails.length + 1,
            title: detail.title,
            detail: detail.detail
          }
        ]
      ];
    });
    setDetailsValues(newDetails);
    let newImages = [];
    safeGet(item, 'images', []).forEach((image) => {
      newImages = [
        ...newImages,
        image
      ];
    });
    setImagesCdnValues(newImages);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleSetProductValues = (e) => {
    setProductValues(e);
  };

  const handleSetExtrasValues = (e) => {
    setExtrasValues(e);
  };

  const handleSetLegalValues = (e) => {
    setLegalValues(e);
  };

  const updateImagesValues = (newImages) => {
    const images = newImages.map(({ file }) => file);
    setImagesValues({ image: images });
  };

  const handleOnClickCheckBox = (id) => {
    const usersUpdated = [
      ...users
    ];
    const index = users.findIndex((x) => x.id === id);
    usersUpdated[index] = {
      ...usersUpdated[index],
      checked: !usersUpdated[index].checked
    };
    setUsers(usersUpdated);
    findCheckedUsers(usersUpdated);
  };

  const handleAction = () => {
    const values = {
      ...{
        user_ids: {
          value: userIds
        }
      },
      ...productValues,
      ...imagesValues,
      ...{
        details: detailsValues
      },
      ...extrasValues,
      ...legalValues
    };

    const validateAllInputs = () => {
      const validateProductValues = validateValues(productValues, setProductValues);
      const validateextrasValues = validateValues(extrasValues, setExtrasValues);
      const validateLegalValues = validateValues(legalValues, setLegalValues);
      return (
        validateProductValues
        && validateextrasValues
        && validateLegalValues
      );
    };

    if (validateAllInputs()) {
      const payload = {};
      Object.keys(values).forEach((value) => {
        if (values[value] instanceof Array) {
          payload[value] = values[value];
        } else {
          payload[value] = values[value].value;
        }
      });
      updateItem({ payload, history });
    } else scrollTop();
  };

  const handleOnClickDeleteImage = (id, item_id) => {
    const payload = {
      image_id: id,
      item_id
    };
    deleteImageItem(payload);
    const imagesCdnValuesFiltered = imagesCdnValues.filter((image) => image.id !== id);
    setImagesCdnValues(imagesCdnValuesFiltered);
  };

  const handleUpdateImageIndex = (data) => {
    updateImageIndex(data);
  };

  return (
    <div className={className}>
      <Text classes={{ root: styles.title }} text={`Editar propiedad ${itemId}`} variant="h2" />
      {
        (itemData.fetching === false)
        && (
        <>
          <Form data={item} type="product" setValuesCallBack={handleSetProductValues} />
          <Divider type="withoutMargins" />
          <ImagesToolGrid
            cdnImages={imagesCdnValues}
            updateImagesCallback={updateImagesValues}
            onClickDelete={handleOnClickDeleteImage}
            updateImageIndex={handleUpdateImageIndex}
            title="Imagenes"
          />
          <Divider />
          <Attributes details={detailsValues} updateDetails={setDetailsValues} title="Detalles" />
          <Divider />
          <Form data={item} type="extras" setValuesCallBack={handleSetExtrasValues} />
          <Divider type="withoutTopMargin" />
          <Form data={item} type="legal" setValuesCallBack={handleSetLegalValues} />
          <Text classes={{ root: styles.title }} text="Asignar" variant="h2" />
          <UsersList users={users} onClick={handleOnClickCheckBox} />
          <Divider />
          <Button classes={{ root: styles.button }} text="Actualizar" onClick={handleAction} />
        </>
        )
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  updateItem: PropTypes.func,
  getUsers: PropTypes.func,
  getAdminItem: PropTypes.func,
  deleteImageItem: PropTypes.func,
  updateImageIndex: PropTypes.func,
  usersData: PropTypes.objectOf(PropTypes.object),
  itemData: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  classes: {},
  updateItem: null,
  getUsers: null,
  getAdminItem: null,
  deleteImageItem: null,
  updateImageIndex: null,
  usersData: {},
  itemData: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  usersData: state.users,
  itemData: state.item
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateItem: updateItemAction,
  getAdminItem: getAdminItemAction,
  deleteImageItem: deleteImageItemAction,
  updateImageIndex: updateImageIndexAction,
  getUsers: getUsersAction

}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default memo(ComponentConnected);
