const languages = {
  'es-ES': {
    'response successfully.': 'Respuesta correcta.',
    success: 'correcto',
    'User login successfully.': 'Se inicio sesión correctamente.',
    'Sesion closed successfully.': 'Se cerro sesión correctamente.',
    'User register successfully.': 'Usuario creado.'
  }
};

export default languages;
