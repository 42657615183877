import React from 'react';
import { XCircle, CheckCircle } from 'react-feather';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Text, Divider } from '..';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes, messages, type, onClick
  } = props;

  const classNameRoot = classNames(
    styles.root,
    classes.root,
    [styles[`root-type-${type}`]],
    [classes[`root-type-${type}`]]
  );

  const classNameMessage = classNames(
    styles.message,
    classes.message
  );

  const handleOnClickCloseIcon = (id) => {
    if (onClick) onClick(id);
  };

  return (
    <div className={classNameRoot}>
      {
        Object.keys(messages).map(
          (id, count) => (
            <div
              key={id}
              onClick={() => handleOnClickCloseIcon(id)}
            >
              <div className={classNameMessage}>
                <div className={styles.messageColumn}>
                  <Text classes={{ root: styles.title }} text={type} variant="input" color="white" />
                  <Text text={messages[id]} variant="body" color="white" />
                </div>
                {
                  type === 'error'
                    ? (
                      <XCircle
                        color="white"
                      />
                    )
                    : (
                      <CheckCircle
                        color="white"
                      />
                    )
                }
              </div>
              {
                (Object.keys(messages).length > 1 && (count + 1) !== Object.keys(messages).length) && <Divider type="withoutMargins" />
              }
            </div>
          )
        )
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  messages: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  classes: {},
  messages: {},
  type: 'success',
  onClick: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
