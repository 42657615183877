import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Check } from 'react-feather';

import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    onClick,
    checked,
    disabled,
    variant
  } = props;

  const classNameRoot = classNames(
    styles.root,
    classes.root,
    styles[`variant-${variant}`],
    classes[`variant-${variant}`],
    {
      [styles.checked]: checked,
      [classes.checked]: checked,
      [styles.disabled]: disabled,
      [classes.disabled]: disabled
    }
  );

  const handlerOnClick = () => {
    if (onClick && disabled !== false) onClick();
  };

  return (
    <div
      className={classNameRoot}
      onClick={() => handlerOnClick()}
    >
      {
        checked
        && (
          variant === 'normal'
            ? <Check color="white" size={22} />
            : <div className={styles.checkDot} />
        )
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string
};

const defaultProps = {
  classes: {},
  onClick: null,
  checked: false,
  disabled: false,
  variant: 'normal'
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
