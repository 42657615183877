import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { scrollTop } from '../../../../utils/helpers';
import constants from '../../../../constants/constants';
import styles from './Styles.module.css';
import { Loading, Messages } from '../../../ui';
import {
  profile as profileAction,
  deleteMessage as deleteMessageAction,
  deleteErrorMessage as deleteErrorMessageAction
} from '../../../../store/actions';

const cookies = new Cookies();

const Component = (props) => {
  const {
    children,
    errorMessages,
    messages,
    fetchings,
    profile,
    deleteErrorMessage,
    deleteMessage
  } = props;

  const [loading, setLoading] = useState(false);
  const [rendering, setRendering] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    scrollTop();
  }, [location]);

  const notAvailablePaths = [
    '/password/recover',
    '/password/reset',
    '/register'
  ];

  useEffect(() => {
    setTimeout(() => {
      setRendering(false);
    }, 1000);
    if (
      cookies.get('token') !== undefined
      && !notAvailablePaths.includes(location.pathname)
    ) profile({ history });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isLoading = false;
    fetchings.forEach((data) => {
      if (data.fetching) isLoading = true;
    });
    setLoading(isLoading);
  }, [fetchings]);

  const handleOnClick = (id, type) => {
    switch (type) {
      case 'success':
        deleteMessage(id);
        break;
      case 'error':
        deleteErrorMessage(id);
        break;
      default:
        break;
    }
  };

  const filterMessageKeys = ['user', 'login', 'logout', 'items', 'item', 'password'];
  const messageFiltered = () => {
    const filtered = {};
    Object.keys(messages).forEach((key) => {
      if (!filterMessageKeys.includes(key)) filtered[key] = messages[key];
    });
    return filtered;
  };

  return (
    <div className={styles.root}>
      {
        (constants.showErrorMessages && Object.keys(errorMessages).length > 0)
        && (
          <Messages messages={errorMessages} type="error" onClick={(id) => handleOnClick(id, 'error')} />
        )
      }
      {children}
      {
        (constants.showSuccesMessages && Object.keys(messageFiltered()).length > 0)
        && (
          <Messages messages={messages} type="success" onClick={(id) => handleOnClick(id, 'success')} />
        )
      }
      { loading && <Loading variant="hidden" />}
      { rendering && <Loading variant="simple" />}
    </div>
  );
};

const propTypes = {
  children: PropTypes.node,
  messages: PropTypes.objectOf(PropTypes.string),
  errorMessages: PropTypes.objectOf(PropTypes.object),
  profile: PropTypes.func,
  deleteMessage: PropTypes.func,
  deleteErrorMessage: PropTypes.func,
  fetchings: PropTypes.arrayOf(PropTypes.object)
};

const defaultProps = {
  children: null,
  messages: {},
  errorMessages: {},
  profile: null,
  deleteMessage: null,
  deleteErrorMessage: null,
  fetchings: []
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  errorMessages: state.errorMessages,
  messages: state.messages,
  fetchings: [
    {
      fetching: state.register.fetching
    },
    {
      fetching: state.login.fetching
    },
    {
      fetching: state.passwordRecover.fetching
    },
    {
      fetching: state.users.fetching
    },
    {
      fetching: state.items.fetching
    },
    {
      fetching: state.item.fetching
    },
    {
      fetching: state.checkout.fetching
    }
  ]
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  profile: profileAction,
  deleteMessage: deleteMessageAction,
  deleteErrorMessage: deleteErrorMessageAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
