import languages from '../constants/languages';
import regex from '../constants/regex';

export const getPathId = (history) => history.location.pathname.split('/').reverse()[0];

export const getString = (key) => {
  const language = 'es-ES';
  const string = languages[language][key];
  if (string) return string;
  return key;
};

export const safeGet = (obj, path, defaultValue = null) => {
  if (!obj || !path) return defaultValue;
  try {
    return String.prototype.split.call(path, /[,[\].]+?/)
      .filter(Boolean)
      .reduce((a, c) => (Object.hasOwnProperty.call(a, c) ? a[c] : defaultValue), obj);
  } catch (e) {
    return defaultValue;
  }
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const toCurrency = (value) => {
  const fixed = (parseInt(value, 10)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `$${fixed}`;
};

export const validateHtml = (str) => (/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/).test(str);

export const validateValues = (values, setValues) => {
  let validate = true;
  const newValues = {};
  Object.keys(values).forEach((id) => {
    const { value, title, required } = values[id];
    newValues[id] = values[id];
    if (required) {
      if (value === '') {
        newValues[id].error = `${title} requerido.`;
        validate = false;
      } else if (value === undefined) {
        newValues[id].error = `El campo "${title.toLowerCase()}" no puede ser indefinido.`;
        validate = false;
      } else if (id === 'whatsapp' && value.length !== 10) {
        newValues[id].error = 'Introduce un numero de telefono valido.';
        validate = false;
      } else if (id === 'rfc' && value.length !== 13) {
        newValues[id].error = 'Introduce un RFC valido.';
        validate = false;
      } else if (regex[id] && !value.match(regex[id].expresion)) {
        newValues[id].error = regex[id].message;
        validate = false;
      } else if (id === 'password_confirmation' && (value !== values.password.value)) {
        newValues[id].error = 'Las contraseñas no coinciden.';
        validate = false;
      // eslint-disable-next-line no-undef
      } else if (id === 'card-number' && !Conekta.card.validateNumber(value)) {
        newValues[id].error = 'Tarjeta invalida.';
        validate = false;
      // eslint-disable-next-line no-undef
      } else if (id === 'month-year' && !Conekta.card.validateExpirationDate(value.substring(0, 2), value.substring(2, 6))) {
        newValues[id].error = 'Fecha invalida.';
        validate = false;
      // eslint-disable-next-line no-undef
      } else if (id === 'cvc' && !Conekta.card.validateCVC(value)) {
        newValues[id].error = 'Cvc invalido.';
        validate = false;
      }
    } else {
      newValues[id].error = null;
    }
  });
  setValues(newValues);
  return validate;
};
