import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Styles.module.css';
import Text from '../Text';
import constants from '../../../constants/constants';
import { toCurrency } from '../../../utils/helpers';

const Component = (props) => {
  const {
    classes,
    onClick,
    data
  } = props;

  const {
    images,
    title,
    zone,
    sale_price,
    real_price,
    capital_gain,
    capital_gain_period,
    discount_percentage
  } = data;

  const classNameRoot = classNames(
    styles.root,
    classes.root
  );
  const handlerOnClick = () => {
    if (onClick) onClick();
  };

  const getImage = () => {
    if (images && images.length > 0) return `${constants.cdn}${images[0].path_file}`;
    return constants.notImageAvailableCatalog;
  };

  return (
    <div
      className={classNameRoot}
      onClick={() => handlerOnClick()}
    >
      <div className={styles.image} style={{ backgroundImage: `url(${getImage()})` }} alt="Imagen del producto" />
      <div className={styles.info}>
        <div className={styles.prices}>
          <Text
            classes={{ root: styles.sale_price }}
            text={toCurrency(sale_price)}
            variant="h3"
          />
          <Text
            classes={{ root: styles.sale_price }}
            text={toCurrency(real_price)}
            variant="stroked"
            color="gray-light"
          />
        </div>
        <div className={styles.zone}>
          <Text
            text={`${zone} / ${title}`}
            variant="body"
            color="gray"
          />
        </div>
        <hr classes={styles.separator} />
        <div className={styles.detail}>
          <div className={styles.capital_gain_container}>
            <div className={styles.capital_gain}>
              <Text
                classes={{ root: styles.sale_price }}
                text={`↑ % ${capital_gain}`}
                variant="capital-gain"
                color="green"
              />
            </div>
            <div className={styles.capital_gain_period}>
              <Text
                classes={{ root: styles.detail_text }}
                text={`Plusvalía ${capital_gain_period}`}
                variant="detail"
                color="gray"
              />
            </div>
          </div>
          <div className={styles.discount_container}>
            <div className={styles.discount_percentage}>
              <Text
                classes={{ root: styles.sale_price }}
                text={`% ${discount_percentage}`}
                variant="capital-gain"
              />
            </div>
            <div className={styles.discount}>
              <Text
                classes={{ root: styles.detail_text }}
                text="Descuento"
                variant="detal"
                color="gray"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  classes: {},
  onClick: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
