import React from 'react';
import PropTypes from 'prop-types';
import styles from './Styles.module.css';
import { Header } from '..';
import { Divider } from '../../../ui';

const Component = (props) => {
  const { children } = props;
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.main}>
        { children }
      </div>
      <Divider type="hidden" />
    </div>
  );
};

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
