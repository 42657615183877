const regex = {
  email: {
    expresion: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    message: 'El correo electrónico no tiene el formato correcto.'
  },
  password: {
    expresion: /^(?=.*[a-z0-9])(?=.{6,})/,
    message: 'La contraseña no debe contener caracteres especiales y debe contener al menos 6 caracteres.'
  },
  password_confirmation: {
    expresion: /^(?=.*[a-z0-9])(?=.{6,})/,
    message: 'La contraseña no debe contener caracteres especiales y debe contener al menos 6 caracteres.'
  },
  whatsapp: {
    expresion: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/,
    message: 'El numero de whatsapp no tiene el formato correcto.'
  }
};

export default regex;
