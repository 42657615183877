export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const DELETE_ERROR_MESSAGE = 'DELETE_ERROR_MESSAGE';

export const FETCH_REGISTER = 'FETCH_REGISTER';
export const REGISTER = 'REGISTER';

export const FETCH_LOGIN = 'FETCH_LOGIN';
export const LOGIN = 'LOGIN';

export const FETCH_GET_PROFILE = 'FETCH_GET_PROFILE';
export const PROFILE = 'PROFILE';

export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const LOGOUT = 'LOGOUT';

export const FETCH_PASSWORD_RECOVER = 'FETCH_PASSWORD_RECOVER';
export const PASSWORD_RECOVER = 'PASSWORD_RECOVER';

export const FETCH_PASSWORD_TOKEN = 'FETCH_PASSWORD_TOKEN';
export const PASSWORD_TOKEN = 'PASSWORD_TOKEN';

export const FETCH_PASSWORD_RESET = 'FETCH_PASSWORD_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';

export const FETCH_GET_USERS = 'FETCH_GET_USERS';
export const USERS = 'USERS';

export const FETCH_UPDATE_USER = 'FETCH_UPDATE_USER';
export const FETCH_UPDATE_PROFILE = 'FETCH_UPDATE_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';

export const FETCH_CHANGE_USER_STATUS = 'FETCH_CHANGE_USER_STATUS';
export const CHANGE_STATUS = 'CHANGE_STATUS';

export const FETCH_GET_ITEMS = 'FETCH_GET_ITEMS';
export const FETCH_GET_ADMIN_ITEMS = 'FETCH_GET_ADMIN_ITEMS';
export const ITEMS = 'ITEMS';
export const ADMIN_ITEMS = 'ADMIN_ITEMS';

export const FETCH_GET_ITEM = 'FETCH_GET_ITEM';
export const FETCH_GET_ADMIN_ITEM = 'FETCH_GET_ADMIN_ITEM';
export const FETCH_POST_ITEM = 'FETCH_POST_ITEM';
export const FETCH_UPDATE_ITEM = 'FETCH_UPDATE_ITEM';
export const FETCH_DELETE_ITEM = 'FETCH_DELETE_ITEM';
export const FETCH_DELETE_IMAGE_ITEM = 'FETCH_DELETE_IMAGE_ITEM';
export const FETCH_UPDATE_IMAGE_INDEX = 'FETCH_UPDATE_IMAGE_INDEX';
export const FETCH_ADD_IMAGE_ITEM = 'FETCH_ADD_IMAGE_ITEM';
export const ITEM = 'ITEM';
export const ADMIN_ITEM = 'ADMIN_ITEM';

export const FETCH_CHECKOUT = 'FETCH_CHECKOUT';
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT';
export const CHECKOUT = 'CHECKOUT';

export const FETCH_PROFILE_SEPARATIONS = 'FETCH_PROFILE_SEPARATIONS';
export const PROFILE_SEPARATIONS = 'PROFILE_SEPARATIONS';

export function addMessage(payload) {
  return {
    type: ADD_MESSAGE,
    payload
  };
}

export function addErrorMessage(payload) {
  return {
    type: ADD_ERROR_MESSAGE,
    payload
  };
}

export function deleteMessage(payload) {
  return {
    type: DELETE_MESSAGE,
    payload
  };
}

export function deleteErrorMessage(payload) {
  return {
    type: DELETE_ERROR_MESSAGE,
    payload
  };
}

export function register(payload) {
  return {
    type: FETCH_REGISTER,
    payload
  };
}

export function profile({ history }) {
  return {
    type: FETCH_GET_PROFILE,
    history
  };
}

export function login({ payload, history }) {
  return {
    type: FETCH_LOGIN,
    payload,
    history
  };
}

export function logout({ history }) {
  return {
    type: FETCH_LOGOUT,
    history
  };
}

export function passwordRecover(payload) {
  return {
    type: FETCH_PASSWORD_RECOVER,
    payload
  };
}

export function passwordToken({ payload, history }) {
  return {
    type: FETCH_PASSWORD_TOKEN,
    payload,
    history
  };
}

export function passwordReset({ payload, history }) {
  return {
    type: FETCH_PASSWORD_RESET,
    payload,
    history
  };
}

export function getUsers() {
  return {
    type: FETCH_GET_USERS
  };
}

export function updateUser({ payload, history }) {
  return {
    type: FETCH_UPDATE_USER,
    payload,
    history
  };
}

export function updateProfile({ payload, history }) {
  return {
    type: FETCH_UPDATE_PROFILE,
    payload,
    history
  };
}

export function changeUserStatus(payload) {
  return {
    type: FETCH_CHANGE_USER_STATUS,
    payload
  };
}

export function getItems(payload = 1) {
  return {
    type: FETCH_GET_ITEMS,
    payload
  };
}

export function getItem(payload) {
  return {
    type: FETCH_GET_ITEM,
    payload
  };
}

export function getAdminItems() {
  return {
    type: FETCH_GET_ADMIN_ITEMS
  };
}

export function getAdminItem(payload) {
  return {
    type: FETCH_GET_ADMIN_ITEM,
    payload
  };
}

export function postItem({ payload, history }) {
  return {
    type: FETCH_POST_ITEM,
    payload,
    history
  };
}

export function updateItem({ payload, history }) {
  return {
    type: FETCH_UPDATE_ITEM,
    payload,
    history
  };
}

export function deleteItem(payload) {
  return {
    type: FETCH_DELETE_ITEM,
    payload
  };
}

export function deleteImageItem(payload) {
  return {
    type: FETCH_DELETE_IMAGE_ITEM,
    payload
  };
}

export function updateImageIndex(payload) {
  return {
    type: FETCH_UPDATE_IMAGE_INDEX,
    payload
  };
}

export function checkout(payload) {
  return {
    type: FETCH_CHECKOUT,
    payload
  };
}

export function clearCheckout() {
  return {
    type: CLEAR_CHECKOUT
  };
}

export function profileSeparations() {
  return {
    type: FETCH_PROFILE_SEPARATIONS
  };
}
