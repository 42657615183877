import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronDown } from 'react-feather';

import styles from './Styles.module.css';
import { Text } from '..';

const Component = (props) => {
  const {
    classes,
    onClick,
    state,
    value,
    options
  } = props;

  const [opened, setOpened] = useState(false);

  const classNameRoot = classNames(
    styles.root,
    styles[`state-${state}`],
    classes.root,
    classes[`state-${state}`]
  );

  const handlerOnClickOption = (id) => {
    if (opened) setOpened(false);
    if (onClick && state !== 'disabled') onClick(id);
  };
  const handlerOnClickOpen = () => {
    if (!opened) setOpened(true);
  };

  return (
    <div
      className={classNameRoot}
      onClick={() => handlerOnClickOpen()}
    >
      <Text
        classes={{ root: styles['text-value'] }}
        text={value}
        variant="bold"
        color="gray"
      />
      <ChevronDown className={styles.icon} />
      {
         (options.length > 0 && opened)
        && (
        <div
          className={styles.options}
          onMouseLeave={() => setOpened(false)}
        >
          {
            options.length > 0
            && options.map(
              ({ id, value: label }) => <Text key={id} classes={{ root: styles['combo-item'] }} text={label} onClick={() => handlerOnClickOption(id)} />
            )
          }
        </div>
        )
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  state: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.array)
};

const defaultProps = {
  classes: {},
  onClick: null,
  state: 'active',
  value: '',
  options: []
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
