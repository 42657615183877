import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { X } from 'react-feather';
import arrayMove from 'array-move';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Compressor from 'compressorjs';

import { Button, Text } from '..';
import styles from './Styles.module.css';
import constants from '../../../constants/constants';

const SortableCdnItem = SortableElement((props) => {
  const {
    id, item_id, file, onClick
  } = props;

  const handleOnClickDeleteIcon = () => {
    if (onClick) onClick(id, item_id);
  };

  return (
    <div key={id} className={styles.imageBox}>
      <div className={styles.deleteIcon} onClick={handleOnClickDeleteIcon}>
        <X color="white" />
      </div>
      <img src={`${constants.cdn}${file}`} alt="Imagen" />
    </div>
  );
});

const SortableItem = SortableElement((props) => {
  const { item, onClick } = props;
  const { id, file } = item;
  const preview = URL.createObjectURL(file);

  const handleOnClickDeleteIcon = () => {
    if (onClick) onClick(id);
  };

  return (
    <div key={id} className={styles.imageBox}>
      <div className={styles.deleteIcon} onClick={handleOnClickDeleteIcon}>
        <X color="white" />
      </div>
      <img src={preview} alt="Imagen" />
    </div>
  );
});

const SortableCdnItem2 = SortableElement((props) => {
  const {
    id, item_id, file, onClick, index
  } = props;

  const handleOnClickDeleteIcon = () => {
    if (onClick) onClick(id, item_id);
  };

  return (
    <div key={id} className={styles.imageBox}>
      <p>{index}</p>
      <div className={styles.deleteIcon} onClick={handleOnClickDeleteIcon}>
        <X color="white" />
      </div>
      <img src={`${constants.cdn}${file}`} alt="Imagen" />
    </div>
  );
});

// eslint-disable-next-line no-unused-vars
const SortableList = SortableContainer((props) => {
  const {
    classes, images, cdnImages, deleteImage, onClickDelete
  } = props;

  const classNameImagesBox = classNames(
    styles.imagesBox,
    classes.imagesBox
  );

  return (
    <div className={classNameImagesBox}>
      {
        cdnImages.map(({ id, item_id, path_file }) => (
          <SortableCdnItem key={`cdn-item-${id}`} id={id} item_id={item_id} file={path_file} onClick={onClickDelete} />
        ))
      }
      {
        images.map((item, index) => (
          <SortableItem key={`item-${item.id}`} index={index} item={item} onClick={deleteImage} />
        ))
      }
    </div>
  );
});

const SortableList2 = SortableContainer(({
  cdnImages, onClickDelete, images, deleteImage
}) => (
  <div className={styles.testBox}>
    {cdnImages.map(({
      id, item_id, path_file
    }, index) => (
      <SortableCdnItem2
        // eslint-disable-next-line react/no-array-index-key
        key={`item-${id}`}
        index={index}
        id={id}
        item_id={item_id}
        file={path_file}
        onClick={onClickDelete}
      />
    ))}
    {
      images.map((item, index) => (
        <SortableItem key={`item-${item.id}`} index={index} item={item} onClick={deleteImage} />
      ))
    }
  </div>
));

const Component = (props) => {
  const {
    classes,
    type,
    title,
    images,
    cdnImages,
    updateImagesCallback,
    onClickDelete,
    updateImageIndex
  } = props;

  const [newImages, setNewImages] = useState(images);
  const [ordered, setOrdered] = useState([]);

  useEffect(() => {
    if (updateImagesCallback) updateImagesCallback(newImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newImages]);

  useEffect(() => {
    setOrdered(cdnImages);
  }, [cdnImages]);

  const classNameRoot = classNames(
    styles.root,
    classes.root,
    styles[type],
    classes[type]
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(ordered, oldIndex, newIndex);
    // console.log(newOrder, 'new images order');
    setOrdered(newOrder);
    const newIndexes = [];
    newOrder.map((item, index) => (
      newIndexes.push(
        { id: item.id, index }
      )
    ));

    const data = {
      images: newIndexes
    };

    updateImageIndex(data);
  };

  const blobToFile = (theBlob) => new File(
    [theBlob], 'property.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }
  );

  const handleAddImage = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/x-png,image/gif,image/jpeg';
    input.onchange = (e) => {
      const file = e.target.files[0];
      // eslint-disable-next-line no-unused-vars
      const compressor = new Compressor(file, {
        quality: 0.6,
        mimeType: 'image/jpeg',
        success(result) {
          setNewImages([
            ...newImages,
            {
              id: `${(newImages.length + 1).toString()}-new`,
              file: blobToFile(result)
            }
          ]);
          /*
          setOrdered([
            ...ordered,
            {
              id: `${(newImages.length + 1).toString()}-new`,
              file: blobToFile(result)
            }
          ]);
          */
        },
        error(err) {
          console.log(err.message);
        }
      });
    };
    input.click();
  };

  const handleDeleteImage = (e) => {
    setNewImages([
      ...newImages.filter(
        (x) => x.id !== e
      )
    ]);
  };

  return (
    <div className={classNameRoot}>
      {
        (title && newImages.length > 0) && <Text classes={{ root: styles['text-title'] }} text={title} variant="h2" />
      }
      <SortableList2
        axis="xy"
        pressDelay={250}
        images={newImages}
        cdnImages={ordered}
        deleteImage={handleDeleteImage}
        onSortEnd={onSortEnd}
        onClickDelete={onClickDelete}
      />
      {/*
      <SortableList
        axis="xy"
        classes={classes}
        images={newImages}
        cdnImages={cdnImages}
        deleteImage={handleDeleteImage}
        // onSortEnd={(e) => onSortEnd(e)}
        onClickDelete={onClickDelete}
      />
      */}
      <Button
        classes={{ root: styles['button-add'] }}
        text="Agregar imagen"
        variant="simple"
        onClick={handleAddImage}
      />
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  cdnImages: PropTypes.arrayOf(PropTypes.object),
  updateImagesCallback: PropTypes.func,
  updateImageIndex: PropTypes.func,
  onClickDelete: PropTypes.func
};

const defaultProps = {
  classes: {},
  type: 'normal',
  title: null,
  images: [],
  cdnImages: [],
  updateImagesCallback: null,
  updateImageIndex: null,
  onClickDelete: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
