import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
import styles from './Styles.module.css';
import { TextEditor } from '..';

const Component = (props) => {
  const {
    classes,
    disabled,
    value,
    color,
    placeholder,
    type,
    onChange,
    onFocus,
    onPressEnter,
    setCardType
  } = props;

  const className = classNames(
    styles.root,
    styles[`color-${color}`],
    classes.root,
    classes[`color-${color}`]
  );

  const handleOnChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  const handleOnChangeCurrency = (e) => {
    if (onChange) onChange(e.target.rawValue.replace('$', ''));
  };

  const handleOnChangeEditor = (e) => {
    if (onChange) onChange(e);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter();
    }
  };

  const handleOnFocus = (e) => {
    if (onFocus) onFocus(e);
  };

  const claveOptions = () => {
    switch (type) {
      case 'currency':
        return {
          prefix: '$',
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        };
      case 'month-year':
        return {
          date: true,
          datePattern: ['m', 'Y']
        };
      case 'card-number':
        return {
          creditCard: true,
          onCreditCardTypeChanged(tipo) {
            if (setCardType) setCardType(tipo);
          }
        };

      case 'cvc':
        return {
          blocks: [4],
          numericOnly: true
        };
      default:
        return {};
    }
  };

  switch (type) {
    case 'currency':
    case 'month-year':
    case 'card-number':
    case 'cvc':
      return (
        <Cleave
          className={className}
          placeholder=""
          value={value}
          options={claveOptions()}
          onFocus={handleOnFocus}
          onChange={handleOnChangeCurrency}
        />
      );
    case 'html':
      return (
        <TextEditor
          className={className}
          classes={{ root: className }}
          placeholder="placeholder"
          value={value}
          options={{
            prefix: '$',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand'
          }}
          onFocus={handleOnFocus}
          onChange={handleOnChangeEditor}
        />
      );
    default:
      return (
        <input
          type={type}
          className={className}
          value={value}
          autoComplete="off"
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
  }
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onPressEnter: PropTypes.func,
  setCardType: PropTypes.func
};

const defaultProps = {
  classes: {},
  type: 'text',
  color: 'active',
  disabled: false,
  value: null,
  placeholder: 'place holder',
  onChange: null,
  onFocus: null,
  onPressEnter: null,
  setCardType: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
