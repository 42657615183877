import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import {
  BackOfficeLayout,
  DefaultLayout,
  CleanLayout,
  CheckoutLayout,
  MainLayout
} from '../components/layouts';
import {
  BackofficeCatalogs,
  BackofficeProduct,
  BackofficeEditProduct,
  BackofficeUsers,
  BackofficeProfile,
  Catalog,
  Checkout,
  Details,
  E404,
  Login,
  PasswordRecover,
  PasswordReset,
  Register,
  SettingsProfile,
  SettingsSeparations,
  Terms,
  Privacy,
  Thanks,
  Ui
} from '../components/pages';

const Component = () => (
  <Router>
    <MainLayout>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/backoffice">
          <Redirect to="/backoffice/users" />
        </Route>
        <Route exact path="/backoffice/catalogs">
          <BackOfficeLayout>
            <BackofficeCatalogs />
          </BackOfficeLayout>
        </Route>
        <Route exact path="/backoffice/product">
          <BackOfficeLayout>
            <BackofficeProduct />
          </BackOfficeLayout>
        </Route>
        <Route exact path="/backoffice/product/:id">
          <BackOfficeLayout>
            <BackofficeEditProduct />
          </BackOfficeLayout>
        </Route>
        <Route exact path="/backoffice/users">
          <BackOfficeLayout>
            <BackofficeUsers />
          </BackOfficeLayout>
        </Route>
        <Route exact path="/backoffice/profile/:id">
          <BackOfficeLayout>
            <BackofficeProfile />
          </BackOfficeLayout>
        </Route>
        <Route exact path="/catalog">
          <DefaultLayout>
            <Catalog />
          </DefaultLayout>
        </Route>
        <Route exact path="/details/:id">
          <DefaultLayout>
            <Details />
          </DefaultLayout>
        </Route>
        <Route exact path="/settings">
          <Redirect to="/settings/profile" />
        </Route>
        <Route exact path="/settings/profile">
          <DefaultLayout>
            <SettingsProfile />
          </DefaultLayout>
        </Route>
        <Route exact path="/settings/separations">
          <DefaultLayout>
            <SettingsSeparations />
          </DefaultLayout>
        </Route>
        <Route exact path="/login">
          <CleanLayout>
            <Login />
          </CleanLayout>
        </Route>
        <Route exact path="/password/recover">
          <CleanLayout>
            <PasswordRecover />
          </CleanLayout>
        </Route>
        <Route exact path="/password/reset">
          <CleanLayout>
            <PasswordReset />
          </CleanLayout>
        </Route>
        <Route exact path="/register">
          <CleanLayout>
            <Register />
          </CleanLayout>
        </Route>
        <Route exact path="/terms">
          <CleanLayout>
            <Terms />
          </CleanLayout>
        </Route>
        <Route exact path="/privacy">
          <CleanLayout>
            <Privacy />
          </CleanLayout>
        </Route>
        <Route exact path="/checkout/:id">
          <CheckoutLayout>
            <Checkout />
          </CheckoutLayout>
        </Route>
        <Route exact path="/thanks"><Thanks /></Route>
        <Route exact path="/ui"><Ui /></Route>
        <Route path="*"><E404 /></Route>
      </Switch>
    </MainLayout>
  </Router>
);

export default Component;
