import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Text, Button } from '..';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes,
    icon,
    title,
    description,
    options
  } = props;

  const classNameRoot = classNames(
    styles.root,
    classes.root

  );

  return (
    <div className={classNameRoot}>
      <div className={styles.menu}>
        { icon && <div className={styles.icon}>{ icon }</div> }
        {
          title && <Text classes={{ root: styles['text-title'] }} text={title} variant="h2" />
        }
        {
          description && <Text text={description} color="dark" />
        }
        {
          options.length > 0 && (
            <div className={styles.options}>
              {
                options.map(({ text, action, variant = 'small' }) => (
                  <Button
                    key="text"
                    classes={{ root: styles[`button-${variant}`] }}
                    text={text}
                    onClick={() => action()}
                    variant={variant}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
};

const defaultProps = {
  classes: {},
  icon: null,
  title: null,
  description: null,
  options: []
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default memo(Component);
