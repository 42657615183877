import stateIds from './stateIds';
import typeIds from './typeIds';
import certificateIds from './certificateIds';

const forms = {
  login: {
    button: {
      text: 'Iniciar sesión'
    },
    inputs: [{
      id: 'email',
      title: 'Correo electrónico',
      required: true,
      variant: 'email',
      value: '',
      placeholder: ''
    },
    {
      id: 'password',
      title: 'Contraseña',
      required: true,
      variant: 'password',
      value: '',
      placeholder: '',
      link: {
        href: '/password/recover',
        label: 'Recuperar'
      }
    }
    ]
  },
  register: {
    button: {
      text: 'Solicitar acceso'
    },
    inputs: [{
      id: 'name',
      title: 'Nombre completo',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'email',
      title: 'Correo electrónico',
      required: true,
      variant: 'email',
      value: '',
      placeholder: ''
    },
    {
      id: 'whatsapp',
      title: 'Whatsapp',
      required: true,
      variant: 'whatsapp',
      value: '',
      placeholder: ''
    },
    {
      id: 'password',
      title: 'Contraseña',
      required: true,
      variant: 'password',
      value: '',
      placeholder: ''
    },
    {
      id: 'password_confirmation',
      title: 'Confirmar contraseña',
      required: true,
      variant: 'password',
      value: '',
      placeholder: ''
    }
    ]
  },
  'password-recover': {
    button: {
      text: 'Recuperar contraseña'
    },
    inputs: [{
      id: 'email',
      title: 'Correo electrónico',
      required: true,
      variant: 'email',
      value: '',
      placeholder: ''
    }
    ]
  },
  'password-reset': {
    button: {
      text: 'Cambiar contraseña'
    },
    inputs: [
      {
        id: 'password',
        title: 'Nueva contraseña',
        required: true,
        variant: 'password',
        value: '',
        placeholder: ''
      },
      {
        id: 'password_confirmation',
        title: 'Confirmar contraseña',
        required: true,
        variant: 'password',
        value: '',
        placeholder: ''
      }
    ]
  },
  checkout: {
    button: {
      text: 'Pagar'
    },
    inputs: [{
      id: 'name',
      title: 'Nombre en la tarjeta',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'card-number',
      title: 'Número en la tarjeta',
      required: true,
      variant: 'card-number',
      value: '',
      placeholder: ''
    },
    {
      id: 'month-year',
      title: 'Mes / Año',
      required: true,
      variant: 'month-year',
      value: '',
      placeholder: ''
    },
    {
      id: 'cvc',
      title: 'CVC',
      required: true,
      variant: 'cvc',
      value: '',
      placeholder: ''
    }
    ]
  },
  profile: {
    button: {
      text: 'Guardar'
    },
    inputs: [{
      id: 'id',
      title: 'Número de socio',
      variant: 'read-only',
      value: '',
      placeholder: ''
    },
    {
      id: 'name',
      title: 'Nombre completo',
      variant: 'read-only',
      value: '',
      placeholder: ''
    },
    {
      id: 'email',
      title: 'Correo electrónico',
      variant: 'read-only',
      value: '',
      placeholder: ''
    },
    {
      id: 'whatsapp',
      title: 'whatsapp',
      required: true,
      variant: 'whatsapp',
      value: '',
      placeholder: ''
    },
    {
      id: 'rfc',
      title: 'RFC',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'address',
      title: 'Calle y número',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'state_id',
      title: 'Estado',
      required: true,
      variant: 'combo',
      comboOptions: stateIds,
      value: '',
      placeholder: ''
    },
    {
      id: 'city_id',
      title: 'Localidad',
      required: true,
      variant: 'text',
      value: '',
      placeholder: ''
    },
    {
      id: 'zip_code',
      title: 'Código postal',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    }
    ]
  },
  'profile-admin': {
    button: {
      text: 'Guardar'
    },
    inputs: [{
      id: 'id',
      title: 'Número de socio',
      variant: 'read-only',
      value: '',
      placeholder: ''
    },
    {
      id: 'name',
      title: 'Nombre completo',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'email',
      title: 'Correo electrónico',
      required: true,
      variant: 'email',
      value: '',
      placeholder: ''
    },
    {
      id: 'whatsapp',
      title: 'whatsapp',
      required: true,
      variant: 'whatsapp',
      value: '',
      placeholder: ''
    },
    {
      id: 'rfc',
      title: 'RFC',
      required: true,
      variant: 'name',
      value: 'TEST012345TESTES98',
      placeholder: ''
    },
    {
      id: 'address',
      title: 'Calle y número',
      required: true,
      variant: 'name',
      value: 'calle x # 1 colonia test',
      placeholder: ''
    },
    {
      id: 'state_id',
      title: 'Estado',
      required: true,
      variant: 'combo',
      comboOptions: stateIds,
      value: 20,
      placeholder: ''
    },
    {
      id: 'city_id',
      title: 'Localidad',
      required: true,
      variant: 'text',
      value: 10,
      placeholder: ''
    },
    {
      id: 'zip_code',
      title: 'Código postal',
      required: true,
      variant: 'name',
      value: '12345',
      placeholder: ''
    }
    ]
  },
  product: {
    inputs: [{
      id: 'type_id',
      size: 3,
      title: 'Tipo',
      required: true,
      variant: 'combo',
      comboOptions: typeIds,
      value: '',
      placeholder: ''
    },
    {
      id: 'certificated',
      size: 3,
      title: 'Certificado',
      required: true,
      variant: 'combo',
      comboOptions: certificateIds,
      value: '',
      placeholder: ''
    },
    {
      id: 'state_id',
      size: 3,
      title: 'Estado',
      required: true,
      variant: 'combo',
      comboOptions: stateIds,
      value: '',
      placeholder: ''
    }, {
      id: 'title',
      size: 3,
      title: 'Título',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'subtitle',
      size: 3,
      title: 'Subtitulo',
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'zone',
      size: 3,
      title: 'Zona',
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'real_price',
      size: 3,
      title: 'Precio de lista',
      required: true,
      variant: 'currency',
      value: '',
      placeholder: ''
    },
    {
      id: 'sale_price',
      size: 3,
      title: 'Precio de remate',
      required: true,
      variant: 'currency',
      value: '',
      placeholder: ''
    },
    {
      id: 'separation_price',
      size: 3,
      title: 'Separación',
      required: true,
      variant: 'currency',
      value: '',
      placeholder: ''
    },
    {
      id: 'capital_gain',
      size: 3,
      title: '% Plusvalía',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'capital_gain_period',
      size: 3,
      title: 'Periodo de Plusvalía',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'discount_percentage',
      size: 3,
      title: '% Descuento',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'short_description',
      size: 1,
      title: 'Descripcion Corta',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'description',
      size: 1,
      title: 'Descripcion',
      required: true,
      variant: 'html',
      value: '',
      placeholder: ''
    }
    ]
  },
  extras: {
    title: 'Extras',
    inputs: [
      {
        id: 'google_maps_url',
        size: 1,
        title: 'Google maps',
        required: true,
        variant: 'string',
        value: '',
        placeholder: ''
      },
      {
        id: 'button_text',
        size: 2,
        title: 'Botón',
        required: true,
        variant: 'text',
        value: '',
        placeholder: ''
      },
      {
        id: 'button_url',
        size: 2,
        title: 'Link URL',
        required: true,
        variant: 'link',
        value: '',
        placeholder: ''
      }
    ]
  },
  legal: {
    title: 'Legal',
    inputs: [
      {
        id: 'legal',
        size: 1,
        title: 'Contenido',
        required: true,
        variant: 'html',
        value: '',
        placeholder: ''
      }
    ]
  },
  'edit-product': {
    inputs: [{
      id: 'type_id',
      size: 3,
      title: 'Tipo',
      required: true,
      variant: 'combo',
      comboOptions: typeIds,
      value: '',
      placeholder: ''
    },
    {
      id: 'certificated',
      size: 3,
      title: 'Certificado',
      required: true,
      variant: 'combo',
      comboOptions: certificateIds,
      value: '',
      placeholder: ''
    },
    {
      id: 'state_id',
      size: 3,
      title: 'Estado',
      required: true,
      variant: 'combo',
      comboOptions: stateIds,
      value: '',
      placeholder: ''
    }, {
      id: 'title',
      size: 2,
      title: 'Título',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'subtitle',
      size: 2,
      title: 'Subtitulo',
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'real_price',
      size: 3,
      title: 'Precio de lista',
      required: true,
      variant: 'currency',
      value: '',
      placeholder: ''
    },
    {
      id: 'sale_price',
      size: 3,
      title: 'Precio de remate',
      required: true,
      variant: 'currency',
      value: '',
      placeholder: ''
    },
    {
      id: 'separation_price',
      size: 3,
      title: 'Separación',
      required: true,
      variant: 'currency',
      value: '',
      placeholder: ''
    },
    {
      id: 'short_description',
      size: 1,
      title: 'Descripcion Corta',
      required: true,
      variant: 'name',
      value: '',
      placeholder: ''
    },
    {
      id: 'description',
      size: 1,
      title: 'Descripcion',
      required: true,
      variant: 'html',
      value: '',
      placeholder: ''
    }
    ]
  },
  'edit-details': {
    button: {
      text: 'Agregar detalle',
      size: 1
    },
    title: 'Detalles',
    inputs: [
    ]
  },
  'edit-extras': {
    title: 'Extras',
    inputs: [
      {
        id: 'google_maps_url',
        size: 1,
        title: 'Google maps',
        required: true,
        variant: 'string',
        value: '',
        placeholder: ''
      },
      {
        id: 'button_text',
        size: 2,
        title: 'Botón',
        required: true,
        variant: 'text',
        value: '',
        placeholder: ''
      },
      {
        id: 'button_url',
        size: 2,
        title: 'Link URL',
        required: true,
        variant: 'link',
        value: '',
        placeholder: ''
      }
    ]
  },
  'edit-legal': {
    title: 'Legal',
    inputs: [
      {
        id: 'legal',
        size: 1,
        title: 'Contenido',
        required: true,
        variant: 'html',
        value: '',
        placeholder: ''
      }
    ]
  }
};

export default forms;
