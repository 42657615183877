import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import styles from './Styles.module.css';
import {
  Options, ProductBox, Text
} from '../../../ui';
import {
  profileSeparations as profileSeparationsAction
} from '../../../../store/actions';
import { safeGet } from '../../../../utils/helpers';

const Component = (props) => {
  const { data, profileSeparations } = props;
  const history = useHistory();

  const options = [
    {
      id: 1,
      value: 'Perfil',
      state: 'selected'
    },

    {
      id: 2,
      value: 'Separaciones',
      state: 'active'
    }
  ];

  const [optionSelected, setOptionSelected] = useState(options[1].id);

  useEffect(() => {
    profileSeparations();
  }, []);

  useEffect(() => {
    if (optionSelected === 1) history.push('/settings/profile');
  }, [optionSelected]);

  const separations = safeGet(data, 'separations.data', []);

  return (
    <div className={styles.root}>
      <div className={styles.options}>
        <Options
          options={options}
          selected={optionSelected}
          className={styles.options}
          onClick={({ id }) => setOptionSelected(id)}
        />
      </div>
      { separations.length > 0
        ? (
          <div className={styles.grid}>
            {
              separations.map((item) => (
                <ProductBox
                  key={item.id}
                  data={item}
                />
              ))
            }
          </div>
        )
        : (
          <Text
            classes={{ root: styles['text-empty'] }}
            text="No cuentas con ninguna separación."
            color="light"
          />
        ) }
    </div>
  );
};

const propTypes = {
  profileSeparations: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  profileSeparations: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.login
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  profileSeparations: profileSeparationsAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
