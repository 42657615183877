import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './Styles.module.css';
import {
  Attributes,
  Button,
  CheckBox,
  ComboBox,
  Divider,
  Form,
  Input,
  InputBox,
  Menu,
  Messages,
  ProductBox,
  Slider,
  Text,
  TextEditor,
  Tooltip
} from '../../ui';

const comboBoxData = {
  value: 'Valor',
  options: [
    {
      id: 1,
      value: 'Valor 1'
    },
    {
      id: 2,
      value: 'Valor muy largo de texto 2'
    },
    {
      id: 3,
      value: 'Valor 3'
    },
    {
      id: 4,
      value: 'Valor 4'
    },
    {
      id: 5,
      value: 'Valor 5'
    },
    {
      id: 6,
      value: 'Valor 6'
    }
  ]
};

const messagesData = {
  tipo: 'Mensaje'
};

export default function Ui() {
  const [backgroundClass, setBackgroundClass] = useState('gray');
  const boxClasses = classNames(
    styles.box,
    styles[`box-${backgroundClass}`]
  );
  return (
    <div className={styles.root}>
      <span className={styles.title}>Ui Examples</span>
      <div className={styles.options}>
        {
        ['white', 'gray', 'black'].map((type) => (
          <button type="button" key={type} className={styles.title} onClick={() => setBackgroundClass(type)}>
            {`Set background ${type}.`}
          </button>
        ))
      }
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Text:</span>
        <Text variant="h1" color="black" text={'"h1 black"'} />
        <Text variant="h1" color="dark" text={'"h1 dark"'} />
        <Text variant="h1" color="gray" text={'"h1 gray"'} />
        <Text variant="h1" color="light" text={'"h1 light"'} />
        <Text variant="h1" color="error" text={'"h1 error"'} />
        <Text variant="h1" color="white" text={'"h1 white"'} />
        <Divider />
        <Text variant="h2" color="black" text={'"h2 black"'} />
        <Text variant="h2" color="dark" text={'"h2 dark"'} />
        <Text variant="h2" color="gray" text={'"h2 gray"'} />
        <Text variant="h2" color="light" text={'"h2 light"'} />
        <Text variant="h2" color="error" text={'"h2 error"'} />
        <Text variant="h2" color="white" text={'"h2 white"'} />
        <Divider />
        <Text variant="h3" color="black" text={'"h3 black"'} />
        <Text variant="h3" color="dark" text={'"h3 dark"'} />
        <Text variant="h3" color="gray" text={'"h3 gray"'} />
        <Text variant="h3" color="light" text={'"h3 light"'} />
        <Text variant="h2" color="error" text={'"h2 error"'} />
        <Text variant="h3" color="white" text={'"h3 white"'} />
        <Divider />
        <Text variant="body" color="black" text={'"body black"'} />
        <Text variant="body" color="dark" text={'"body dark"'} />
        <Text variant="body" color="gray" text={'"body gray"'} />
        <Text variant="body" color="light" text={'"body light"'} />
        <Text variant="body" color="error" text={'"body error"'} />
        <Text variant="body" color="white" text={'"body white"'} />
        <Divider />
        <Text variant="bold" color="black" text={'"bold black"'} />
        <Text variant="bold" color="dark" text={'"bold dark"'} />
        <Text variant="bold" color="gray" text={'"bold gray"'} />
        <Text variant="bold" color="light" text={'"bold light"'} />
        <Text variant="bold" color="error" text={'"bold error"'} />
        <Text variant="bold" color="white" text={'"bold white"'} />
        <Divider />
        <Text variant="input" color="black" text={'"input black"'} />
        <Text variant="input" color="dark" text={'"input dark"'} />
        <Text variant="input" color="gray" text={'"input gray"'} />
        <Text variant="input" color="light" text={'"input light"'} />
        <Text variant="input" color="error" text={'"input error"'} />
        <Text variant="input" color="white" text={'"input white"'} />
        <Divider />
        <Text variant="footer" color="black" text={'"footer black"'} />
        <Text variant="footer" color="dark" text={'"footer dark"'} />
        <Text variant="footer" color="gray" text={'"footer gray"'} />
        <Text variant="footer" color="light" text={'"footer light"'} />
        <Text variant="footer" color="error" text={'"footer error"'} />
        <Text variant="footer" color="white" text={'"footer white"'} />
        <Divider />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>CheckBox:</span>
        <CheckBox variant="normal" checked />
        <CheckBox variant="normal" checked={false} />
        <CheckBox variant="circle" checked />
        <CheckBox variant="circle" checked={false} />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Divider:</span>
        <Divider />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Button:</span>
        <Button state="active" variant="normal" text={'"normal active"'} onClick={() => console.log('Normal Click "active" Button.')} />
        <Button state="selected" variant="normal" text={'"normal selected"'} onClick={() => console.log('Normal Click "selected" Button.')} />
        <Button state="disabled" variant="normal" text={'"normal disabled"'} onClick={() => console.log('Normal Click "disabled" Button.')} />
        <Divider />
        <Button state="active" variant="simple" text={'"simple active"'} onClick={() => console.log('simple Click "active" Button.')} />
        <Button state="selected" variant="simple" text={'"simple selected"'} onClick={() => console.log('simple Click "selected" Button.')} />
        <Button state="disabled" variant="simple" text={'"simple disabled"'} onClick={() => console.log('simple Click "disabled" Button.')} />
        <Divider />
        <Button state="active" variant="simpleInverted" text={'"simple inverted active"'} onClick={() => console.log('simple inverted Click "active" Button.')} />
        <Button state="selected" variant="simpleInverted" text={'"simple inverted selected"'} onClick={() => console.log('simple inverted Click "selected" Button.')} />
        <Button state="disabled" variant="simpleInverted" text={'"simple inverted disabled"'} onClick={() => console.log('simple inverted Click "disabled" Button.')} />
        <Divider />
        <Button state="active" variant="small" text={'"small active"'} onClick={() => console.log('Small Click "active" Button.')} />
        <Button state="selected" variant="small" text={'"small selected"'} onClick={() => console.log('Small Click "selected" Button.')} />
        <Button state="disabled" variant="small" text={'"small disabled"'} onClick={() => console.log('Small Click "disabled" Button.')} />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Input:</span>
        <Input value="Value" />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>InputBox:</span>
        <InputBox title={'"email normal"'} state="normal" variant="email" value="" />
        <InputBox title={'"email active"'} state="active" variant="email" value="" />
        <InputBox title={'"email error"'} state="error" variant="email" value="" />
        <InputBox title={'"password normal"'} state="normal" variant="password" value="" />
        <InputBox title={'"password active"'} state="active" variant="password" value="" />
        <InputBox title={'"password error"'} state="error" variant="password" value="" />
        <InputBox title={'"combo normal"'} state="normal" variant="combo" value="" />
        <InputBox title={'"combo active"'} state="active" variant="combo" value="" />
        <InputBox title={'"combo error"'} state="error" variant="combo" value="" />
        <InputBox title={'"numeric normal"'} state="normal" variant="numeric" value="" />
        <InputBox title={'"numeric active"'} state="active" variant="numeric" value="" />
        <InputBox title={'"numeric error"'} state="error" variant="numeric" value="" />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Form:</span>
        <Form />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Attributes:</span>
        <Attributes />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>ProductBox:</span>
        <ProductBox />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Menu:</span>
        <Menu
          items={[
            {
              id: 'Link',
              text: 'Link',
              onClick: () => {}
            }
          ]}
        />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Slider:</span>
        <Slider />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>ComboBox:</span>
        <ComboBox text="Combo Box" value={comboBoxData.value} options={comboBoxData.options} />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Message:</span>
        <Messages messages={messagesData} />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Message:</span>
        <TextEditor
          messages={messagesData}
        />
      </div>
      <div className={boxClasses}>
        <span className={styles.title}>Tooltip:</span>
        <Tooltip
          text="Hola..."
        />
      </div>
    </div>
  );
}
