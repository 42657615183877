import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { Menu } from '../../../ui';
import logo from '../../../../media/logo.svg';
import styles from './Styles.module.css';

// Actions...
import { logout as logoutAction } from '../../../../store/actions';

const Component = (props) => {
  const { logout } = props;

  const history = useHistory();

  const menuItemsLeft = [
    {
      id: 'catalogo',
      text: 'Catálogo',
      onClick: () => history.push('/backoffice/catalogs')
    },
    {
      id: 'usuarios',
      text: 'Usuarios',
      onClick: () => history.push('/backoffice/users')
    }
  ];

  const menuItemsRight = [
    {
      id: 'salir',
      text: 'Salir',
      onClick: () => logout({ history })
    }
  ];

  return (
    <div className={styles.root}>
      <Menu items={menuItemsLeft} />
      <img src={logo} className={styles.logo} alt="logo" />
      <Menu items={menuItemsRight} />
    </div>
  );
};

const propTypes = {
  logout: PropTypes.func
};

const defaultProps = {
  logout: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout: logoutAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
