import React from 'react';
import { useLightbox } from 'simple-react-lightbox';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Styles.module.css';

const Component = (props) => {
  const {
    idx, title, subTitle, description, image, index
  } = props;

  // Class Names...
  const classNameSlide = classNames(styles.slide, styles[`slide${idx}`]);
  const classNameSlideInfo = classNames(styles.slideInfo);
  const classNameSlideImage = classNames(styles.slideImage);
  const classNameSlideTitle = classNames(styles.slideTitle);
  const classNameSlideSubTitle = classNames(styles.slideSubTitle);
  const classNameSlideDescription = classNames(styles.slideDescription);
  const { openLightbox } = useLightbox();
  return (
    <div
      key={`slide-${idx}`}
      className={classNameSlide}
      onClick={() => { openLightbox(index); }}
    >
      {(title || subTitle || description) && (
      <div className={classNameSlideInfo}>
        {title && (
        <div className={classNameSlideTitle}>
          <h2>{title}</h2>
        </div>
        )}
        {subTitle && (
        <div className={classNameSlideSubTitle}>
          <h1>{subTitle}</h1>
        </div>
        )}
        {description && (
        <div className={classNameSlideDescription}>
          <p>{description}</p>
        </div>
        )}
      </div>
      )}
      {image && (
        <div
          // style={{ backgroundImage: `url(${image})` }}
          className={classNameSlideImage}
        >
          <img src={image} alt="banner" />
        </div>
      )}
    </div>
  );
};

const propTypes = {
  idx: PropTypes.string,
  showButtons: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number
};

const defaultProps = {
  idx: '0',
  showButtons: false,
  title: null,
  subTitle: null,
  description: null,
  image: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
