import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from './Styles.module.css';
import {
  Button,
  Text,
  Options,
  PopupMenu
} from '../../../ui';
import {
  getUsers as getUsersAction,
  changeUserStatus as changeUserStatusAction
} from '../../../../store/actions';

const Component = (props) => {
  const { getUsers, changeUserStatus, data } = props;

  const [optionSelected, setOptionSelected] = useState(1);
  const [displayPopupMenu, setDisplayPopupMenu] = useState(false);
  const [userStatusTemp, setUserStatusTemp] = useState(0);

  const popupMenuOptions = [
    {
      text: 'Cancelar',
      action: () => {
        setDisplayPopupMenu(false);
      }
    },
    {
      text: 'Aceptar',
      action: () => {
        changeUserStatus(userStatusTemp);
        setDisplayPopupMenu(false);
      }
    }
  ];
  const history = useHistory();

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.fetching === false && data.data === undefined) {
      setTimeout(() => {
        // history.push('/');
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const keys = ['name', 'email', 'whatsapp'];

  const options = [
    {
      id: 1,
      value: 'Todo'
    },
    {
      id: 2,
      value: 'Solicitudes'
    },
    {
      id: 3,
      value: 'Activos'
    }
  ];

  const titles = ['Usuario', 'Correo electrónico', 'Whatsapp', 'Opciones'];

  const handleOnClickStatus = ({ id, status_id, role_id }) => {
    if (role_id !== 1) {
      const payload = {
        user_id: id,
        status_id: (status_id === 2) ? 1 : 2
      };
      setUserStatusTemp(payload);
      setDisplayPopupMenu(true);
    }
  };

  const handleOnClickUpdateInfo = (id) => {
    history.push(`/backoffice/profile/${id}`);
  };

  const getTitle = () => {
    let title = 'Usuarios';
    if (data?.data !== undefined) title += ` ${data.data.length}`;
    return title;
  };

  const usersFiltered = () => {
    let newData = [];
    if (data && data.data) {
      switch (optionSelected) {
        case 3:
          newData = data.data.filter((x) => x.status_id === 1);
          break;
        case 2:
          newData = data.data.filter((x) => x.status_id === 2);
          break;
        default:
          newData = data.data;
          break;
      }
    }
    const sortedItems = [
      ...newData.sort((a, b) => a.id - b.id)
    ];
    return sortedItems;
  };

  return (
    <div className={styles.root}>
      {
        displayPopupMenu && (
          <PopupMenu
            description="¿Estás seguro que deseas actualizar el status?"
            options={popupMenuOptions}
          />
        )
      }
      <div className={styles.header}>
        <div className={styles.titles}>
          <Text text={getTitle()} variant="h2" />
          <Button text="Nuevo Usuario" variant="simpleInverted" onClick={() => history.push('/register')} />
        </div>
        <div className={styles.options}>
          <Options
            classes={{ button: styles.optionButton }}
            options={options}
            selected={optionSelected}
            onClick={({ id }) => setOptionSelected(id)}
          />
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          {
            titles.map((key) => (
              <div key={key} id={key} className={(key !== 'Opciones') ? styles.column : styles['column-options']}>
                <Text text={key} variant="bold" />
              </div>
            ))
          }
        </div>
        {
          (!data.fetching && usersFiltered().length > 0) && usersFiltered().map((userData) => (
            <div key={userData.id} className={styles.row}>
              {
                keys.map((key, index) => (
                  <div key={userData[key]} className={styles.column}>
                    <Text text={userData[key]} variant={index === 0 ? 'bold' : 'body'} color={index !== 0 ? 'grey' : 'black'} />
                  </div>
                ))
              }
              <div className={styles['column-options']}>
                <Button
                  text="Editar"
                  state={userData.role_id === 1 ? 'disabled' : 'active'}
                  variant="simple"
                  onClick={() => handleOnClickUpdateInfo(userData.id)}
                />
                <Button
                  text={userData.status_id === 1 ? 'Desactivar' : 'Activar'}
                  state={(userData.role_id === 1) ? 'disabled' : 'active'}
                  variant="simple"
                  onClick={() => handleOnClickStatus(userData)}
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

const propTypes = {
  getUsers: PropTypes.func,
  changeUserStatus: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  getUsers: null,
  changeUserStatus: null,
  data: {}
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.users
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getUsers: getUsersAction,
  changeUserStatus: changeUserStatusAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
