import React from 'react';
import styles from './Styles.module.css';
import { Text } from '../../ui';

export default function E404() {
  return (
    <div className={styles.root}>
      <div className={styles.e404Box}>
        <Text text="404" variant="h2" />
        <Text text={'Lo sentimos página no encontrada. ¯\\_(ツ)_/¯...'} color="gray" />
      </div>
    </div>
  );
}
