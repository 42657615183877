const constants = {
  api: 'https://triback.lagom.agency/api',
  // api: 'http://127.0.0.1:8000/api',
  cdn: 'https://triback.lagom.agency/storage/',
  // cdn: 'http://127.0.0.1:8000/storage',
  conektaApiKey: 'key_KLRaxBRz6yKcNsL3TGcwqvw',
  googleApiKey: 'AIzaSyCBgPr2onpidSPFrnwU6RUYoA2Z2EqcexI',
  notImageAvailableCatalog: 'https://via.placeholder.com/490x340?text=Not Image Available',
  notImageAvailableDetail: 'https://via.placeholder.com/1000x420?text=Not Image Available',
  showSuccesMessages: true,
  showErrorMessages: true,
  deleteMessageDelay: 4000
};

export default constants;
