import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Styles.module.css';

const Component = (props) => {
  const {
    classes
  } = props;
  const classNameRoot = classNames(
    styles.root,
    classes.root
  );

  return (
    <div className={classNameRoot}>
      {
        [1, 2, 3].map((bullet) => (
          <div key={bullet} className={styles.bullet} />
        ))
      }
    </div>
  );
};

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  bullets: PropTypes.arrayOf(PropTypes.array)
};

const defaultProps = {
  classes: {},
  bullets: [1, 2, 3]
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
