import { bindActionCreators } from 'redux';
import { Tag } from 'react-feather';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { getPathId, safeGet, toCurrency } from '../../../utils/helpers';
import {
  Text,
  Divider
} from '../../ui';
import {
  getItem as getItemAction
} from '../../../store/actions';
import constants from '../../../constants/constants';
import styles from './Styles.module.css';

const Component = (props) => {
  const { getItem, data } = props;

  const history = useHistory();

  useEffect(() => {
    getItem(getPathId(history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.fetching === false && (data === null || data.data === null)) {
      setTimeout(() => {
        history.push('/catalog');
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getImage = () => {
    const images = safeGet(data, 'data.images', []);
    if (images.length > 0) return `${constants.cdn}${images[0].path_file}`;
    return constants.notImageAvailableCatalog;
  };

  return (
    data.data ? (
      <div className={styles.root}>
        <div className={styles.header}>
          <img className={styles.image} src={getImage()} alt="Imagen" />
        </div>
        <div className={styles.prices}>
          <div className={styles.price}>
            <Text text="Precio real" variant="input" />
            <Text classes={{ root: styles.real }} text={toCurrency(data.data.real_price)} variant="h3" color="light" />
          </div>
          <div className={styles.price}>
            <Text text="Precio de venta" variant="input" />
            <Text text={toCurrency(data.data.sale_price)} variant="h3" />
          </div>
          <div className={styles['last-price']}>
            <Text text="Separación" variant="input" />
            <Text text={toCurrency(data.data.separation_price)} variant="h3" childrenPosition="left">
              <Tag className={styles.tagIcon} />
            </Text>
          </div>
          <Divider />
          <div className={styles.price}>
            <Text text="Total a pagar" variant="input" />
            <Text text={toCurrency(data.data.separation_price)} variant="h3" />
          </div>
        </div>
      </div>
    ) : (
      <Text text="Loading..." />
    )
  );
};

const propTypes = {
  getItem: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.object)
};

const defaultProps = {
  getItem: null,
  data: null
};

Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: state.item
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getItem: getItemAction
}, dispatch);

const ComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ComponentConnected;
